import {
    SET_CHECK1, CLEAR_CHECK1
} from "../../actions/dc";

const _null = null;

export default (state = _null, { type, val}) => {
    Object.freeze(state);
    switch (type) {
        case SET_CHECK1:
            return val;
        case CLEAR_CHECK1:
            return _null
        default:
            return state;
    }
};