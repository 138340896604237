import {
  SET_ASSEMBLY_LIST, CLEAR_ASSEMBLY_LIST
} from "../../actions/stopsApp";


const _nullList = []; 
             
export default (state = _nullList, { type, list }) => {
  Object.freeze(state);
  switch (type) {
    case SET_ASSEMBLY_LIST:
      return list;
    case CLEAR_ASSEMBLY_LIST: 
      return _nullList
    default:
      return state;
  }
};