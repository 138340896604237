import {
    SET_DIAPHRAGM, CLEAR_DIAPHRAGM
} from "../../actions/parts";

const _null = null;

export default (state = _null, { type, truefalse }) => {
    Object.freeze(state);
    switch (type) {
        case SET_DIAPHRAGM:
            return truefalse;
        case CLEAR_DIAPHRAGM:
            return _null
        default:
            return state;
    }
};