import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useSelector, useDispatch} from "react-redux";
import MainApp from "../applications/main"

export default function AlternativeLanding(){
    return (
    
          <MainApp />
    
    );
}