import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import InputGroup from 'react-bootstrap/InputGroup';
import Alert from 'react-bootstrap/Alert';
import { useState, useEffect, useRef, useCallback} from "react";
import NumberPad from "./NumberPad"
import {useSelector, useDispatch} from "react-redux";
import { updateTargetElement, resetUpdatedValue }  from "../actions/NumberPad"

import { getTestReport, updateRepairs, updateFinalCheck2,
 updateFinalCheck1, updateFDoubleCheck,
  updateFinalRvPressdrop, updateFinalRvOpenedAt, 
  updateFinalAirOpened, updateFinalCheckPressdrop
} from "../actions/report"

export default function FinalXVBAForm(){
	const [disc, setDisc] = useState(null); 
	const [spring, setSpring] = useState(null); 
	const [guide, setGuide] = useState(null); 
	const [diaph, setDiaph] = useState(null); 
	const [seat, setSeat] = useState(null); 
	const [o_ring, setO_ring]= useState(null); 
	const [modules, setModules] = useState(null); 
	const [rubber_kit, setRubberKit] = useState(null); 
	const [air_inlet_disc, setAirInletDisc] = useState(null); 
	const [air_inlet_spring, setAirInletSpring] = useState(null); 
	const [check_disc, setCheckDisc] = useState(null); 
	const [check_spring, setCheckSpring] = useState(null); 
	const [float, setFloat] = useState(null); 
	const report = useSelector((state) => state.report)
	const reportID = useSelector((state) => state.report_id)
	const final_air_opened = useSelector((state) => state.final_air_opened)
	const final_check_pressdrop = useSelector( (state) => state.final_check_pressdrop)
	const [checkPass, setCheckPass] = useState(false)
	const [openFully, setOpenFully] = useState(false)

	const [comment, setComment] = useState(null)
	const [repaired, setRepaired] = useState(false); 
	const [cleaned, setCleaned] = useState(false) ;

	const dispatch = useDispatch()  
	const [pressdrop, setPressdrop] = useState(false)
	const [rv_pass, setRvPass] = useState(false)
	const [finalPass, setFinalPass] = useState(null)
	const [finalFail, setFinalFail] = useState(null)
	const [check2_tight, setCheck2Tight] = useState(false)
	const [check1_tight, setCheck1Tight] = useState(false)

		const [allowInput, setAllowInput] = useState( ( repaired || cleaned))

	useEffect(()=>{
		setAllowInput( (repaired || cleaned) )
	}, [repaired, cleaned])


	useEffect(()=>{	
		if(final_check_pressdrop >= 1.0 ){
			setCheckPass(true)
		}else{
			setCheckPass(false)
		}
	}, [final_check_pressdrop])



	useEffect(()=>{
		if(report != null){
			setCleaned(report.cleaned)
			setRepaired(report.repaired)
			setDisc(report.disc); 
			setSpring(report.spring); 
			setGuide(report.guide); 
			setDiaph(report.diaphragm); 
			setModules(report.module); 
			setSeat(report.seat); 
			setO_ring(report.o_ring); 
			setRubberKit( report.rubber_kit); 
			setAirInletSpring(report.air_spring); 
			setAirInletDisc(report.air_disc); 
			setCheckDisc(report.check_disc); 
			setCheckSpring(report.check_spring); 
			setFloat(report.ff)
			setOpenFully(report.repair_vb_air_opened_fully)
			dispatch( updateFinalAirOpened(report.repair_vb_air_opened_at) )
			dispatch( updateFinalCheckPressdrop(report.repair_vb_check_pressdrop) )

			setFinalPass(report.repair_pass)
			document.querySelector("#final_pass").checked = report.repair_pass
			document.querySelector("#final_fail").checked = report.repair_fail
			setFinalFail(report.repair_fail)

		}
	}, [report])


	const redirectKeyboard = (event)=>{
		dispatch( updateTargetElement(event.target.id) ).then((data, err) =>{
			if(err){
				console.log(err)
			}
		})
	}


	return(
		<Container fluid>
			<Row>
				<Col>	
					<hr />
					<strong> This Device Was: </strong>
				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Check id = "cleaned" type="checkbox" label="Repaired" checked = {repaired} onChange= { (event)=>{ setRepaired(event.target.checked)}}/>
				</Col>
				<Col>
					<Form.Check id = "repaired" type ="checkbox" label ="Cleaned"  checked = {cleaned} onChange= { (event)=>{ setCleaned(event.target.checked)}}/>
				</Col>
			</Row>
			<Row>
				<Col>
					<hr/>
					<strong>Parts</strong>
				</Col>
			</Row>
			<Row xs = {2} sm md  lg = {3}>

				<Col>
					<Form.Check id="disc" label ="Disc" checked = {disc} onChange= { (event)=>{ setDisc(event.target.checked)}} disabled = {!allowInput}/>
				</Col>
				<Col>
					<Form.Check id ="spring" label ="Spring" checked = {spring} onChange= { (event)=>{ setSpring(event.target.checked)}} disabled = {!allowInput}/>
				</Col>
				<Col>
					<Form.Check id ="guide" label ="Guide" checked = {guide} onChange= { (event)=>{ setGuide(event.target.checked)}}  disabled = {!allowInput}/>
				</Col>
				<Col>
					<Form.Check id ="diaph" label ="Diaphragm" checked = {diaph} onChange= { (event)=>{ setDiaph(event.target.checked)}} disabled = {!allowInput}/>
				</Col>
				<Col>
					<Form.Check  id ="seat" label ="Seat" checked = {seat} onChange= { (event)=>{ setSeat(event.target.checked)}} disabled = {!allowInput}/>
				</Col>
				<Col>
					<Form.Check id ="o_ring" label ="O-Ring(s)" checked = { o_ring} onChange= { (event)=>{ setO_ring(event.target.checked)}} disabled = {!allowInput}/>
				</Col>	
				<Col>
					<Form.Check id ="module" label ="Module" checked = {modules} onChange= { (event)=>{ setModules(event.target.checked)}} disabled = {!allowInput}/>
				</Col>
				<Col>

					<Form.Check id ="rubber_kit" label ="Rubber Kit" checked={rubber_kit} onChange= { (event)=>{ setRubberKit(event.target.checked)}} disabled = {!allowInput}/>
				</Col>
				<Col>
					<Form.Check id ="air_disc" label ="Air Inlet Disc" checked = { air_inlet_disc} onChange= { (event)=>{ setAirInletDisc(event.target.checked)}} disabled = {!allowInput}/>
				</Col>
				<Col>
					<Form.Check id ="air_spring" label ="Air Inlet Spring" checked={air_inlet_spring} onChange= { (event)=>{ setAirInletSpring(event.target.checked)}} disabled = {!allowInput}/>
				</Col>
				<Col>
					<Form.Check id ="check_disc" label ="Check Disc" checked = {check_disc} onChange= { (event)=>{ setCheckDisc(event.target.checked)}} disabled = {!allowInput}/>
				</Col>
				<Col>
					<Form.Check id ="check_spring" label ="Check Spring" checked = { check_spring} onChange= { (event)=>{ setCheckSpring(event.target.checked)}} disabled = {!allowInput}/>
				</Col>
				<Col>
					<Form.Check  id ="float" label ="Float" checked = {float} onChange= { (event)=>{ setFloat(event.target.checked)}} disabled = {!allowInput} />
				
				</Col>
			</Row>
			<Row>
				<Col>
					<hr/>
					<Row>
						<Col>
							<strong> Air Inlet</strong>
						</Col>
						<Col>
							<Form.Check 
								label = "Open Fully"
								id ="final_air_open_fully"
								checked = { openFully}
								onClick = { (event) => setOpenFully(event.target.checked)}
								disabled = {!allowInput}
							/>
						</Col>
					</Row>
				</Col>
				
			</Row>
			<Row xs = {1}>
				<Col>
					<InputGroup>
						<InputGroup.Text> Opened At </InputGroup.Text>
						<Form.Control 
							id="final_air_opened"
							value = {final_air_opened}
							onClick = { redirectKeyboard }
							readOnly = {true}
							disabled = {!allowInput}
						/>
						
					</InputGroup>
					<strong> Check Valve </strong>
					<InputGroup>
						<InputGroup.Text>Pressdrop</InputGroup.Text>
						<Form.Control 
							id="final_check_pressdrop"
							value = {final_check_pressdrop}
							onClick = { redirectKeyboard }
							readOnly = {true}
							disabled = {!allowInput}
						/>
						{
							checkPass ?
								<Badge bg = "success"> Pass</Badge>
							: 
								<Badge bg = "secondary"> Fail </Badge>
						}
					</InputGroup>
					
				</Col>
				<Col>
					
				</Col>
			</Row>
			<Row>
				<Col xs = {4}>
					<Container fluid>

							<hr/>
							<Row>

								<Col>
									<Form.Check  disabled = {!allowInput} id = "final_pass" 
									label = "PASSED" type = "radio" 
									checked = {finalPass} 
									onChange = {(event) => {
										setFinalPass(event.target.checked)
										setFinalFail(!event.target.checked)
									}}/>
								</Col>
								<Col>
									
									<Form.Check disabled = {!allowInput} 
									id = "final_fail" label = "FAILED"  type="radio" 
									checked = {finalFail} 
									onChange = {(event) =>{
										setFinalPass(!event.target.checked)
										setFinalFail(event.target.checked)
									} } />
								</Col>

							</Row>
							
						</Container>
				</Col>
				<Col xs= {8}>
					<hr/>
					<NumberPad />
				</Col>
			</Row>
		</Container>
	)
}