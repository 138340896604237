export const SET_LINES = "SET_LINES"
export const CLEAR_LINES = "CLEAR_LINES"
export const SET_REMOVED = "SET_REMOVED"
export const CLEAR_REMOVED = "CLEAR_REMOVED"
export const SET_NEW_SERIAL = "SET_NEW_SERIAL"
export const CLEAR_NEW_SERIAL = "CLEAR_NEW_SERIAL"

const setLines = (truefalse) => ({ type: SET_LINES, truefalse })
const setRemoved = (truefalse) => ({ type: SET_REMOVED, truefalse })
const setNewSerial = (val) => ({ type: SET_NEW_SERIAL, val })

const clearRemoved = () => ({ type: CLEAR_REMOVED })
const clearLines = () => ({ type: CLEAR_LINES })
const clearNewSerial = () => ({ type: CLEAR_NEW_SERIAL })

export const clearAll = () => dispatch => {
    return new Promise(async (resolve, reject) => {
        let p1 = dispatch(clearRemoved())
        let p2 = dispatch(clearLines())
        let p3 = dispatch(clearNewSerial())
        Promise.all([p1, p2, p3]).then((data, err) => {
            resolve()
        })
    })
}

export const reduceRemoved = (truefalse) => dispatch => {
    return new Promise(async (resolve, reject) => {
        dispatch( setRemoved(truefalse) )
        resolve()
    })
}

export const reduceLines = (truefalse) => dispatch => {
    return new Promise(async (resolve, reject) => {
        dispatch(setLines(truefalse))
        resolve()
    })
}
export const reduceNewSerial = (val) => dispatch => {
    return new Promise(async (resolve, reject) => {
        dispatch(setNewSerial(val))
        resolve()
    })
}

