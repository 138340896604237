

export const SET_SYSTEM_PSI = "SET_SYSTEM_PSI"
export const SET_DMR = "SET_DMR"
export const CLEAR_DMR = "CLEAR_DMR"
export const CLEAR_SYSTEM_PSI = "CLEAR_SYSTEM_PSI"

/*CLEAR UP */
const clearDMR = () => ({ type: CLEAR_DMR })
const clearSystemPSI = () => ({ type: CLEAR_SYSTEM_PSI })

export const clearSystem = () => dispatch => {
    return new Promise((resolve, reject) => {
        let p1 = dispatch(clearDMR())
        let p2 = dispatch(clearSystemPSI())
        Promise.all([p1, p2]).then((data, err) => {
            resolve(); 
        })
    })
}

/*SETTERS*/
const setDMR = (dmr) => ({
    type: SET_DMR,
    dmr
})

const setSystemPSI = (psi) => ({
    type: SET_SYSTEM_PSI,
    psi
})
export const reduceDMR = (dmr) => dispatch => {
    return new Promise((resolve, reject) => {
        console.log("Reducing dmr: ", dmr)
        dispatch(setDMR(dmr))
        resolve()
    })
}
export const reduceSystemPSI = (psi) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setSystemPSI(psi))
        resolve()
    })
}

