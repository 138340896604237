import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { useState, useEffect, useRef, useCallback} from "react";
import { login } from "../actions/session.js"; 
import {useSelector, useDispatch} from "react-redux";
import format from 'date-fns/format'; 
import { logout, getTestList } from "../actions/session"; 


export default function LoginForm(){
	const today = new Date(); 
	const [email, setEmail]  = useState(""); 
	const [password, setPassword] = useState(""); 
	const [validated, setValidated] = useState(false); 
	const [credentialsStateShow, setCredentialsStateShow] = useState(false); 
	const dispatch = useDispatch(); 
	const loginSuccess = useSelector((state)=>state.session.success);
	const [spinnerShow, setSpinnerShow] = useState(false)

	useEffect(()=>{
		if(loginSuccess == null){
			setCredentialsStateShow(false); 
		}else if(loginSuccess == false){
			setCredentialsStateShow(true); 
		}else{
			setCredentialsStateShow(false)
		}

	}, [loginSuccess]) 

	const handleSubmit = (event) =>{
		event.preventDefault();
		setSpinnerShow(true); 
		let obj = { username: email, password: password } 
		dispatch( login(obj)).then((data, err)=>{
			if(data){
				dispatch( getTestList(format(today, 'yyyy-MM-dd'))).then((data, err)=>{
					setSpinnerShow(false)
				}); 
			}else{
				setSpinnerShow(false)
			}
			
		})
	}

	return(
		<Container fluid>
			<Row>
				<Modal centered show={spinnerShow}>
					<Modal.Body>
						<Spinner />
					</Modal.Body>
				</Modal>
			</Row>
			<Form noValidate validated={validated} onSubmit={handleSubmit} className="login-form">
				<Row>
						<Alert  variant="danger" show = { credentialsStateShow } >
							<p> Invalid login credentials. Please try again, reset your password or contact our office for further assistance. </p>
						</Alert>
				</Row>
				<Row>
						<Form.Group>
				            <Form.Label>Email:</Form.Label>
				            <Form.Control type="email" name="email" value={email} onChange={ (e) => setEmail(e.target.value) } required/>
				        </Form.Group>
				        <Form.Group>
				            <Form.Label> Password: </Form.Label>
				            <Form.Control  type="password" name="password" value={password} onChange={ (e) => setPassword(e.target.value) } maxlength="250" required/>
				         </Form.Group>
				</Row>
				<Row>
				</Row>
				<Row> 
					<Col> 
				          <Button variant ="dark" style={ {marginTop: "3vh"} } type="submit" > Submit </Button>
					</Col>
				</Row>
			</Form>
		</Container>
	)
}