/* eslint-disable import/no-anonymous-default-export */
import {
    SET_COMMENT, CLEAR_COMMENT
} from "../../actions/remarksApp";

const _null = null;

export default (state = _null, { type, comment}) => {
    Object.freeze(state);
    switch (type) {
        case SET_COMMENT:
            return comment;
        case CLEAR_COMMENT:
            return _null
        default:
            return state;
    }
};