import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    reduceAssembly,
    reduceInstallation,
    reduceOrientation,
    reduceAirgap,
    reduceUSC
} from "../../../actions/approvedApp"


function Editable() {
    const assembly = useSelector((state) => state.approved_assembly)
    const installation = useSelector((state) => state.approved_installation)
    const orientation = useSelector((state) => state.approved_orientation)
    const airgap = useSelector((state) => state.approved_airgap)
    const usc = useSelector((state) => state.approved_usc)
    const dispatch = useDispatch() 

    return (
        <>
            <Row xs={2} sm={2} md lg={5}>
                <Col>
                    <Form.Check
                        label="Assembly"
                        checked={assembly}
                        onChange={
                            (event) => {
                                dispatch( reduceAssembly ( event.target.checked ) )
                            }
                        }
                    />
                </Col>
                <Col>
                    <Form.Check
                        label="Installation"
                        checked={installation}
                        onChange={
                            (event) => {
                                dispatch(reduceInstallation(event.target.checked))
                            }
                        }
                    />
                </Col>
                <Col>
                    <Form.Check
                        label="Orientation"
                        checked={orientation}
                        onChange={
                            (event) => {
                                dispatch(reduceOrientation(event.target.checked))
                            }
                        }
                    />
                </Col>
                <Col>
                    <Form.Check
                        label="Airgap"
                        checked={airgap}
                        onChange={
                            (event) => {
                                dispatch(reduceAirgap(event.target.checked))
                            }
                        }
                    />
                </Col>
                <Col>
                    <Form.Check
                        label="USC"
                        checked={usc}
                        onChange={
                            (event) => {
                                dispatch(reduceUSC(event.target.checked))
                            }
                        }
                    />

                </Col>
            </Row>
        </>
    )
}

export default function Approved() {
    return (
        <Container>
            <Row>
                <Col>
                    <strong> Approved </strong>
                </Col>
            </Row>
          
                    <Editable />
          
       

            
        </Container>
    )
}