import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import { useSelector, useDispatch } from "react-redux";
import {
    reduceCleaned, reduceRepaired
} from "../../../actions/finalResults"

export default function CleanRepair() {
    const repaired = useSelector((state) => state.repaired)
    const cleaned = useSelector((state) => state.cleaned)
    const dispatch = useDispatch()

    return (
        <Container>
            <Row>
                <Col>
                    <strong> This test report was: </strong>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Check
                        type="checkbox"
                        label="Cleaned"
                        value={cleaned}
                        onChange = {
                            (event) => {
                                dispatch( reduceCleaned(event.target.checked) )
                            }
                        }
                    />
                </Col>
                <Col>
                    <Form.Check
                        type="checkbox"
                        label="Repaired"
                        value={repaired}
                        onChange={
                            (event) => {
                                dispatch(reduceRepaired(event.target.checked))
                            }
                        }
                    />
                </Col>
            </Row>
        </Container>
    )
}