import {
  SET_ORDER, CLEAR_ORDER
} from "../../actions/map";


const _nullList = []; 
             
export default (state = _nullList, { type, list }) => {
  Object.freeze(state);
  switch (type) {
    case SET_ORDER:
      return list;
    case CLEAR_ORDER:
      return _nullList;
    case '':
      return _nullList
    default:
      return state;
  }
};