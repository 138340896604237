import {
    SET_RP_OPENED_AT, CLEAR_RP_OPENED_AT
} from "../../actions/rp";

const _null = null;

export default (state = _null, { type, val }) => {
    Object.freeze(state);
    switch (type) {
        case SET_RP_OPENED_AT:
            return val;
        case CLEAR_RP_OPENED_AT:
            return _null
        default:
            return state;
    }
};