import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import InputGroup from 'react-bootstrap/InputGroup';
import Alert from 'react-bootstrap/Alert';
import { useState, useEffect, useRef, useCallback} from "react";
import NumberPad from "./NumberPad"
import {useSelector, useDispatch} from "react-redux";
import { getTestReport, 
updateDoubleCheck,
 updateCheck1,
  updateCheck2,
  updateSystemPSI,
  updateDMR,
   updateApproved, 
   updateRemarks, 
   updateSystem, 
   updateTestType, 
   updatePipesize, 
	updatePhysicalSeparation, 
	updateFinalPipesize, 
	updateFinalPhysicalSeparation
} from "../actions/report"
import { updateTargetElement, resetUpdatedValue }  from "../actions/NumberPad"

export default function AGForm(){
	const [pass, setPass] = useState(null)
	const [fail, setFail] = useState(null)

	const [showAlert, setShowAlert] = useState(false)
	const [alertMessage, setAlertMessage] = useState("")
	const [assembly, setAssembly] = useState(null); 
	const [installation, setInstallation] = useState(null); 
	const [orientation, setOrientation] = useState(null); 
	const [airgap, setAirgap] = useState(null); 
	const [usc, setUsc] = useState(null); 
	const [so1, setSo1] = useState(null)
	const [so2, setSo2] = useState(null); 
	const [upstream, setUpstream] = useState(null)
	const [so1_rusted, setSo1_rusted] = useState(null);
	const [so2_rusted, setSo2_rusted] = useState(null); 
	const [upstream_rusted, setUpstream_rusted] = useState(null); 
	const [restored, setRestored] = useState(null)
	const [typeII, setTypeII] = useState(false)
	const [comment, setComment] = useState(null)
	const [testType, setTestType] = useState("E")

	const reportID = useSelector( (state) => state.report_id)
	const dispatch = useDispatch()
	const check1 = useSelector( (state) => state.check1_psid)
	const check2 = useSelector( (state) => state.check2_psid)
	const systemPSI = useSelector((state) => state.system_psi)
	const dmr = useSelector((state) => state.dmr)
	const report = useSelector( (state) => state.report)
	const [loaded, setLoaded] = useState(false)
	const [check1_tight, setCheck1_tight] = useState(null)
	const [check2_tight, setCheck2_tight] = useState(null)

	const [handle1, setHandle1] = useState(document.querySelector("#check1_psid") )

	const [allowWrite, setAllowWrite] = useState(true)
	const pipesize = useSelector( (state) => state.pipesize)
	const physical_separation = useSelector( (state) => state.physical_separation )


	useEffect(()=>{

		let obj = {}; 
		obj.type = testType

		dispatch( updateTestType(reportID, obj ) ).then((data, err)=>{
			console.log("Done updating test type")
		}) 
	}, [testType])


	useEffect(()=>{
		if(report != null){
			//setBlock2(true)
			setAssembly(report.approved_assembly)
			setOrientation(report.approved_orientation)
			setInstallation(report.approved_installation)
			setAirgap(report.approved_airgap)
			setUsc(report.approved_USC)
			setSo1(report.so1)
			setSo1_rusted(report.so1_rusted)
			setSo2(report.so2)
			setSo2_rusted(report.so2_rusted)
			setUpstream(report.upstream)
			setUpstream_rusted(report.upstream_rusted)
			setRestored( report.restored)
			setPass(report.initialTest_pass )
			setFail(report.initialTest_fail)
			setComment(report.comments)

			dispatch( updatePipesize( report.pipesize) );
			dispatch( updatePhysicalSeparation(report.physical_separation)) 

			dispatch( updateSystemPSI(report.initialTest_system_psid) ) 
			dispatch( updateDMR(report.initialTest_dmr) )
			setTestType(report.serviceType)
			
		}
	}, [report])
	

	const redirectKeyboard = (event)=>{
		dispatch( updateTargetElement(event.target.id) ).then((data, err) =>{
			if(err){
				console.log(err)
			}
		})
	}

	return(
		<Container fluid>
			<Alert show = {showAlert} dismissible onClose = { () => setShowAlert(false) }> 
				{alertMessage}
			</Alert>

			<Form>

				<Row>
					<Col>
						<hr/>
						<InputGroup size="sm">
							<InputGroup.Text> Test Type</InputGroup.Text>
							<Form.Select
								value= {testType}
								onChange = { (event)=>{
									setTestType(event.target.value)
								}}
							>
							<option value={''}>Select One </option>
							<option value = "E"> EXISTING</option>
							<option value = "RM"> REMOVED </option>
							<option value = "NEW"> NEW </option>
							<option value = "RP"> REPAIRED </option>
							<option value = "RPL"> REPLACED </option>
						</Form.Select>
						</InputGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<hr/>
						<strong> Approved </strong> 
					</Col>
				</Row>
				<Row xs={2} sm md lg = {5}>
				
					<Col>
						<Form.Check 
							id="ap_assembly"
							label ="Assembly"
							checked = {assembly}
							onChange = {(event) => {
								setAssembly(event.target.checked)
							}}
						/> 
					</Col>
					<Col>
						<Form.Check 
							id="ap_installation"
							label = "Installation" 
							checked = {installation}
							onChange = {(event) => { 
								setInstallation(event.target.checked)
							}}		
								
						/>
					</Col>

					<Col>
						<Form.Check 
							id = "ap_orientation"
							label = "Orientation" 
							checked = {orientation}
							onChange = {(event) => setOrientation(event.target.checked)}
						/>
					</Col>
					<Col>
						<Form.Check 
							id="ap_airgap"
							label = "Airgap" 
							checked = {airgap}
							onChange = {(event) => setAirgap(event.target.checked)}
						/>
					</Col>
					<Col>
						<Form.Check 
							id="ap_usc"
							label = "USC" 
							checked = {usc}
							onChange = {(event) => setUsc(event.target.checked)}
						/>
					</Col>
			</Row>

			<Row>
			
				<Col>
				<hr/>
				<Row>
					<Col>
					<strong> Air Gap </strong>
					</Col>
				</Row>
				<InputGroup>
					<InputGroup.Text> Pipesize</InputGroup.Text>
					<Form.Control 
						id="pipesize"
						onClick = { redirectKeyboard }
						value = {pipesize}
						readOnly = {true}
						
					/>
				</InputGroup>
				<InputGroup>
					<InputGroup.Text> Physical Separation </InputGroup.Text>
					<Form.Control
						id="physical_separation"
						onClick = { redirectKeyboard }
						value = {physical_separation}
						readOnly =  {true}
					/>
					
				</InputGroup>
				<InputGroup>
					<InputGroup.Text> System PSI</InputGroup.Text>
					<Form.Control 
						id="systemPSI"
						onClick = { redirectKeyboard }
						value = {systemPSI}
						readOnly = {true}
					 />
				</InputGroup>
				<InputGroup>
					<InputGroup.Text> DMR</InputGroup.Text>

					<Form.Control 
						id ="DMR"
						onClick = { redirectKeyboard }
						value = {dmr}
						readOnly = {true}
					/>
				</InputGroup>
			
				</Col>
				
			</Row>
			<Row>
				<Col xs = {4}>
					<Container fluid>

							<hr/>
							<Row>

								<Col>
									<Form.Check 
										label = "PASSED" 
										type = "radio" 
										id = "TEST_PASS"
										checked={pass} 
										onChange = {(event) => {
											setPass(event.target.checked) 
											setFail(!event.target.checked)
										}}
									
									/>
								</Col>
								<Col>
									
									<Form.Check 
										label = "FAILED"  
										type="radio" 
										id = "TEST_FAIL"
										checked = {fail} 
										onChange = { (event) => {
												setFail( event.target.checked) 
												setPass(!event.target.checked)
											}
											
										}
								
									/>

								</Col>

							</Row>
							<Row>
								<Col>
									<hr/>
									<Form.Check 
										id = "restored"
										label = "System Restored?"  
										type="checkbox"
										checked = { restored }
										onChange = {(event)=> setRestored(event.target.checked)}
									 />
								</Col>
							</Row>
						</Container>
				</Col>
				<Col xs= {8}>
					<hr/>
					<NumberPad />
				</Col>
			</Row>
			<Row>
				<Col>
					<hr/>
					<InputGroup>
						<InputGroup.Text> Comment </InputGroup.Text> 
						<Form.Control
							id= "comment"
							value= {comment}
							onChange = {(event) => setComment(event.target.value)}
						 />
					</InputGroup>
				</Col>
			</Row>
			<Row>	
				<Col>
					<hr/>
					<strong> Remarks </strong>
				</Col>
			</Row>
			<Row>
				<Col xs ={3} sm md lg = {2}>
					<Form.Label> SO #1 </Form.Label>
				</Col>
				<Col>	
					<Form.Check 
						id = "so1"
						type = "radio" 
						label= "On" 
						name = "so1"
						onChange = {
							(event) => { 
								setSo1(event.target.checked)
							}
						}	
						checked = { so1 }
					/>
				</Col>
				<Col>
					<Form.Check 
						type = "radio" 
						label="Off"
						name = "so1"
						onChange = {
							(event) => { 
								setSo1(!event.target.checked)
							}
						}
						checked = { !so1 }
					 />
				</Col>
				<Col>
					<Form.Check 
						id = "so1_rusted"
						type = "checkbox" 
						label="Rusted"
						onChange = { (event)=> setSo1_rusted(event.target.checked) }
						checked = { so1_rusted }
					 />
				</Col>

			</Row>
			<Row>
				<Col xs ={3} sm md lg = {2}>
					<Form.Label> SO #2 </Form.Label>
				</Col>
				<Col>	
					<Form.Check 
						id = "so2"
						type = "radio" 
						label="On"
						onChange = {
							(event) => { 
								setSo2(event.target.checked)
							}
						}	
						checked = { so2 }
					 />
				</Col>
				<Col>
					<Form.Check 

						type = "radio" 
						label="Off"
						onChange = {
							(event) => { 
								setSo2( !event.target.checked)
							}
						}	
						checked = { !so2 }
					/>
				</Col>
				<Col>
					<Form.Check 
						id = "so2_rusted"
						type = "checkbox" 
						label="Rusted"
						onChange = { (event)=> setSo2_rusted(event.target.checked) }
						checked = { so2_rusted } 
					/>
				</Col>
				
			</Row>

			<Row>
				<Col xs ={3} sm md lg = {2}>
					<Form.Label> Upstream </Form.Label>
				</Col>
				<Col>	
					<Form.Check 
						id = "upstream"
						type = "radio" 
						label="On"
						onChange = {
							(event) => { 
								setUpstream(event.target.checked)
							}
						}	
						checked = { upstream }
					/>
				</Col>
				<Col>
					<Form.Check 
						type = "radio" 
						label="Off"

						onChange = {
							(event) => { 
								setUpstream( !event.target.checked)
							}
						}	
						checked = { !upstream }
					/>
				</Col>
				<Col>
					<Form.Check 
						id = "upstream_rusted"
						type = "checkbox" 
						label="Rusted"
						onChange = { (event)=> setUpstream_rusted(event.target.checked) }
						checked = { upstream_rusted } 
					/>
				</Col>
				
			</Row>
			
			</Form>

		</Container>
	)
}