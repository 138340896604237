/* eslint-disable import/no-anonymous-default-export */
import {
    SET_AI_DID_NOT_OPEN, CLEAR_AI_DID_NOT_OPEN
} from "../../actions/xvba";

const _null = null;

export default (state = _null, { type, truefalse }) => {
    Object.freeze(state);
    switch (type) {
        case SET_AI_DID_NOT_OPEN:
            return truefalse;
        case CLEAR_AI_DID_NOT_OPEN:
            return _null
        default:
            return state;
    }
};