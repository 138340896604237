import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form'

export default function NotTested(props){
    return(
        <Modal show = {true} centered>
            <Modal.Header closeButton onHide = { ()=>{
                props.closeModal() 
            }}>
            </Modal.Header>
            <Modal.Body>
                   not tested
            </Modal.Body>
            <Modal.Footer>  

            </Modal.Footer>
        </Modal>
    )

}