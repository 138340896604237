import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import DailyDispatch from "./DailyDispatch/DailyDispatch"
import DispatchDate from "./date/date"
import Settings from "./settings/app"
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../actions/session"


export default function MainApp() {
    const [appToShow, setAppToShow] = useState(<DailyDispatch />)
    const dispatch = useDispatch()

	return(
        <div className="whole-app">
            
                <Navbar expand="lg" variant= "dark" bg= "dark"> 
                    <Container fluid>
                   
                    
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                    <Nav.Link href="#home"
                                        onClick = {()=>{
                                        setAppToShow(<DailyDispatch />)
                                    }}
                                    >Daily Dispatch</Nav.Link>
                                    <Nav.Link href="#home" 
                                    onClick = {()=>{
                                        setAppToShow(<Settings />)
                                    }}>
                                    Settings</Nav.Link>
                                    <Button onClick = { () => dispatch ( logout () ) }>
                                        Log Out
                                    </Button>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                    </Navbar>
          
            <Row>
                <Col>
                   {appToShow}
                </Col>
            </Row>
        </div>
	)
}