import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import DoubleCheck from "./DoubleCheck"
import ReliefValve from "./ReliefValve"
import XVB from "./XVB"
import CleanRepair from "./CleanRepair"
import NumberPad from "../NumberPad"
import Parts from "./Parts"
import PassFail from "./PassFail"
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'


export default function Final() {
    const type = useSelector((state) => state.assembly_type);
    const size = useSelector((state) => state.assembly_size);
    const testType = useSelector((state) => state.test_type); 
    const [showApp, setShowApp] = useState(true)

    const [formToShow, setFormToShow] = useState(<DoubleCheck />)

    useEffect(() => {
        switch (testType) {
            case "NEW":
            case "E":
                setShowApp(true)
                switch (type) {
                    
                    case "DC":
                    case "DCDA":
                    case "DCDAII":
                        setFormToShow(<DoubleCheck />)
                        break;
                    case "RP":
                    case "RPDA":
                    case "RPDAII":
                        setFormToShow(<ReliefValve />)
                        break;
                    case "AVB":
                    case "SVB":
                    case "PVB":
                        setFormToShow(<XVB />)
                        break;
                    case "AG":
                        setShowApp(false)
                        setFormToShow(<>Nothing to show here</>)
                        break;
                    default:
                        setFormToShow(<DoubleCheck />)
                        break;
                }
                break;
            case "REPLACED":
            case "RM":
                setShowApp(false)
                setFormToShow(<> </>)
                break;
    
            default:
                setFormToShow(<DoubleCheck />)
        }
    }, [type, testType])


    return (
        <>
            {
                showApp? 
                    <Container fluid>
                        <Row>
                            <Col>
                                <CleanRepair />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Parts />
                            </Col>
                        </Row>
                        <Row>
                            <Col >
                                {formToShow}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3} sm={5}>
                                 <PassFail />
                            </Col>
                            <Col>
                                <NumberPad />
                            </Col>
                        </Row>
                    </Container>
                : 
                    <>
                        nothing to show here
                    </>
            
            }
            


        </>
    )

}