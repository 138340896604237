import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Approved from "./Approved"
import DoubleCheck from "./DoubleCheck"
import ReliefValve from "./ReliefValve"
import XVB from "./XVB"
import System from "./System"
import Remarks from "./Remarks"
import NumberPad from "../NumberPad"
import PassFail from "./PassFail"
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import AVB from "./AVB"
import Airgap from "./Airgap"
import Replaced from "./Replaced"
import Removed from "./Removed"

export default function Initial() {
    const type = useSelector((state) => state.assembly_type);
    const size = useSelector((state) => state.assembly_size);
    const testType = useSelector((state) => state.test_type); 
    const [showApp, setShowApp] = useState(true)

    const [formToShow, setFormToShow] = useState(<DoubleCheck />)

    useEffect(() => {
        switch (testType) {
            case "NEW": 
            case "E":
                setShowApp(true)
                switch (type) {
                    case "DC":
                    case "DCDA":
                    case "DCDAII":
                        setFormToShow(<DoubleCheck  />)
                        break;
                    case "RP":
                    case "RPDA":
                    case "RPDAII":
                        setFormToShow(<ReliefValve />)
                        break;
                    case "AVB":
                    case "SVB":
                    case "PVB":
                        setFormToShow(<XVB />)
                        break;
                    case "AG":
                        setFormToShow(<Airgap />)
                        break;
                    default:
                        setFormToShow(<DoubleCheck />)
                        break;
                }
                break; 
            case "REPLACED":
            case "RM": 
                setShowApp(false)
                setFormToShow(<Removed />)
                break;
            default:
                setFormToShow( <DoubleCheck /> )
        }
    }, [type, testType])


    return (
        <>
            {
                showApp ?
                    <>
                        <Container fluid>
                            <Row>
                                <Col>
                                    <Approved />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {formToShow}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <System />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4} sm={4}>
                                    <PassFail />
                                </Col>
                                <Col xs = {5} sm = {5} md={5}>
                                    <NumberPad />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Remarks />
                                </Col>
                            </Row>
                        </Container>
                    </>
                : 
                    <>
                        {formToShow}
                    </>
            
            }
          
            
        
        </>
    )

}