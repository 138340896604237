import {
  SET_BILLING_ID, CLEAR_BILLING_ID
} from "../../actions/invoiceApp";

const _nullID = null; 
             
export default (state = _nullID, { type, id }) => {
  Object.freeze(state);
  switch (type) {
    case SET_BILLING_ID:
      return id;
    case CLEAR_BILLING_ID: 
      return _nullID
    default:
      return state;
  }
};