import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import configureStore from './store/store';
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {checkLoggedIn} from "./util/session"; 


const renderApp = preloadedState =>{
  const root = ReactDOM.createRoot(document.getElementById('root'));
  const store = configureStore(preloadedState); 
  window.getState = store.getState;
  root.render(
    <Provider store={store}>
        <App />
      </Provider>
  );
}
(async ()=> renderApp(await checkLoggedIn() ) ) (); 
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();