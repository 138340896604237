export const getAssembly = async (id) => (
	fetch("backflow/getter/assembly?" + new URLSearchParams({
		id: id
	}), {
		method: "GET",
		headers: {
			"Content-Type": "application/json"
		}
	})
);

export const getReport = async (id) => (
	fetch("backflow/getter/report?" + new URLSearchParams({
		id: id
	}), {
		method: "GET",
		headers: {
			"Content-Type": "application/json"
		}
	})
);

