import {
    SET_AI_OPENED_AT, CLEAR_AI_OPENED_AT
} from "../../actions/xvba";

const _null = null;

export default (state = _null, { type, val }) => {
    Object.freeze(state);
    switch (type) {
        case SET_AI_OPENED_AT:
            return val;
        case CLEAR_AI_OPENED_AT:
            return _null
        default:
            return state;
    }
};