import {
    SET_DISPATCH_DATE
} from "../../actions/stopsApp";

import { format } from "date-fns"

const _nullDate = format( new Date(), 'yyyy-MM-dd' );

export default (state = _nullDate, { type, date }) => {
    Object.freeze(state);
    switch (type) {
        case SET_DISPATCH_DATE:
            return date;
        case '':
            return date; 
        default:
            return state;
    }
};