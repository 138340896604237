import {
    SET_PRESSDROP, CLEAR_PRESSDROP
} from "../../actions/rp";

const _null = null;

export default (state = _null, { type, val }) => {
    Object.freeze(state);
    switch (type) {
        case SET_PRESSDROP:
            return val;
        case CLEAR_PRESSDROP:
            return _null
        default:
            return state;
    }
};