/* eslint-disable no-unused-vars */
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import MyBody from "./MyBody"
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import {
    updateAssembly, updateAirgap,
    updateDoubleCheck, updateParts,
    updateReliefValve, updateRemarks,
    updateRemoved, updateSystem, updateXVBA, updateApproved, updateServiceStatus, updateGaugeDetails, updateSubmitter
} from "../../actions/results"
import { clearAssembly } from "../../actions/assemblyApp"
import { clearApproved } from "../../actions/approvedApp"
import { clearAG } from "../../actions/ag"
import { clearDoubleCheck } from "../../actions/dc"
import { clearFinalResults } from "../../actions/finalResults"
import { clearInitialResults } from "../../actions/initialPassFailApp"
import { clearParts } from "../../actions/parts"
import { clearRemarks } from "../../actions/remarksApp"
import { clearAll } from "../../actions/removed"
import { clearRP } from "../../actions/rp"
import { clearSystem } from "../../actions/systemApp"
import { clearXVBA } from "../../actions/xvba"
import { clearReportID, clearAssemblyID } from "../../actions/stopsApp"
import { clearPad } from "../../actions/NumberPad"
import {
	requestStopServices,
} from "../../actions/stopsApp"

import NotTested from "./NotTested"


export default function TestReport() {
    const [show, setShow] = useState(true)
    const reportID = useSelector((state) => state.reportID)
    const assemblyID = useSelector((state) => state.assembly_id)
    const dispatch = useDispatch()
    const stopID = useSelector((state) => state.stopID)
    const [showNotTested, setShowNotTested] = useState(false) 

    useEffect(() => {
        if (reportID != null) {
            //load report information and set to show
            setShow(true)
        }else {
            setShow(false)
        }
    }, [reportID])


    const [saving, setSaving] = useState(false)
    const [savingMessage, setSavingMessage] = useState("")

    /* Assembly */
    const serial_number = useSelector((state) => state.assembly_serial)
    const location = useSelector((state) => state.assembly_location)
    const size = useSelector((state) => state.assembly_size)
    const make = useSelector((state) => state.assembly_make) 
    const model = useSelector((state) => state.assembly_model)
    const hazard = useSelector((state) => state.assembly_hazard)
    const type = useSelector((state) => state.assembly_type)
    

    /*Approved*/
    const airgap = useSelector((state) => state.approved_airgap)
    const assembly = useSelector((state) => state.approved_assembly)
    const installation = useSelector((state) => state.approved_installation)
    const orientation = useSelector((state) => state.approved_orientation)
    const usc = useSelector((state) => state.approved_usc)

    /*airgap*/
    const pipesize = useSelector((state) => state.pipesize)
    const physical_separation = useSelector((state) => state.physical_separation)

    /*Double Check */
    const check1 = useSelector((state) => state.check1); 
    const check1_tight = useSelector((state) => state.check1_tight); 
    const check1_leaked = useSelector((state) => state.check1_leaked); 
    const check2 = useSelector((state) => state.check2); 
    const check2_tight= useSelector((state) => state.check2_tight);
    const check2_leaked = useSelector((state) => state.check2_leaked); 
    const finalCheck1 = useSelector((state) => state.final_check1); 
     const finalCheck2 = useSelector((state) => state.final_check2);

    const fcheck1_tight = useSelector((state) => state.final_check1_tight);
    const fcheck1_leaked = useSelector((state) => state.final_check1_leaked);
   
    const fcheck2_tight = useSelector((state) => state.final_check2_tight);
    const fcheck2_leaked = useSelector((state) => state.final_check2_leaked);
    const dc_typeII = useSelector((state) => state.dc_typeII)

    /*Pass Fail*/
    const pass = useSelector((state) => state.initial_pass); 
    const fail = useSelector((state) => state.initial_fail); 
    const fPass = useSelector((state) => state.final_pass);
    const fFail = useSelector((state) => state.final_fail);
    const restored = useSelector((state) => state.restored)

    /* Relief Valve*/
    const rp_opened_at = useSelector((state) => state.rp_opened_at)
    const final_rp_opened_at = useSelector((state) => state.final_rp_opened_at)
    const pressdrop = useSelector((state) => state.pressdrop)
    const final_pressdrop = useSelector((state) => state.final_pressdrop)
    const rp_did_not_open = useSelector((state) => state.rp_did_not_open)
    const final_rp_did_not_open = useSelector((state) => state.final_rp_did_not_open)
    const rp_typeII = useSelector((state) => state.rp_typeII)

    /*XVBA */
    const ai_did_not_open = useSelector((state) => state.ai_did_not_open)
    const ai_opened_at = useSelector((state) => state.ai_opened_at)
    const ai_opened_fully = useSelector((state) => state.ai_opened_fully)
    const check_valve = useSelector((state) => state.check_valve)
    const final_ai_did_not_open = useSelector((state) => state.final_ai_did_not_open)
    const final_ai_opened_at = useSelector((state) => state.final_ai_opened_at)
    const final_ai_opened_fully = useSelector((state) => state.final_ai_opened_fully)
    const final_check_valve = useSelector((state) => state.final_check_valve)
    const final_check_valve_failed = useSelector((state) => state.final_check_valve_failed)

    /* parts*/
    const ai_disc = useSelector((state) => state.ai_disc)
    const ai_spring = useSelector((state) => state.ai_spring)
    const check_disc = useSelector((state) => state.check_disc)
    const check_spring = useSelector((state) => state.check_spring)
    const diaphragm = useSelector((state) => state.diaphragm)
    const disc = useSelector((state) => state.disc)
    const float = useSelector((state) => state.ff)
    const guide = useSelector((state) => state.guide)
    const md = useSelector((state) => state.module)
    const oring = useSelector((state) => state.oring)
    const rubber_kit = useSelector((state) => state.rubber_kit)
    const seat = useSelector((state) => state.seat)
    const spring = useSelector((state) => state.spring)

    /* remarks*/
    const so1 = useSelector((state) => state.so1)
    const so2 = useSelector((state) => state.so2)
    const upstream = useSelector((state) => state.upstream)
    const so1_rusted = useSelector((state) => state.so1_rusted)
    const so2_rusted = useSelector((state) => state.so2_rusted)
    const upstream_rusted = useSelector((state) => state.upstream_rusted)
    const comment = useSelector((state) => state.comment)

    /* removed replaced*/
    const removed = useSelector((state) => state.removed)
    const lines = useSelector((state) => state.lines)
    const new_serial = useSelector((state) => state.new_serial)

    /* system*/
    const system_dmr = useSelector((state) => state.system_dmr)
    const system_psi = useSelector((state) => state.system_psi)
    const testType = useSelector((state) => state.test_type)

    /*Repaired cleaned*/
    const repaired = useSelector((state) => state.repaired)
    const cleaned = useSelector((state) => state.cleaned)

    function packSystem() {
        let obj = {}; 
        obj.dmr = system_dmr; 
        obj.psi = system_psi; 
        obj.repaired = repaired; 
        obj.cleaned = cleaned;
        obj.pass = pass;
        obj.fail = fail;
        obj.final_pass = fPass;
        obj.final_fail = fFail;
        obj.restored = restored; 
        obj.testType = testType; 
        return obj; 
    }
    function packRemoved() {
        let obj = {}
        obj.removed = removed; 
        obj.lines = lines; 
        obj.new_serial = new_serial; 
        return obj; 
    }
    function packRemarks() {
        let obj = {};
        obj.so1 = so1; 
        obj.so2 = so2; 
        obj.upstream = upstream; 
        obj.so1_rusted = so1_rusted; 
        obj.so2_rusted = so2_rusted; 
        obj.upstream_rusted = upstream_rusted; 
        obj.comment = comment; 
        return obj; 
    }
    function  packParts(){
        let obj = {}; 
        obj.ai_disc = ai_disc; 
        obj.ai_spring = ai_spring; 
        obj.check_disc = check_disc; 
        obj.check_spring = check_spring;
        obj.diaphragm = diaphragm; 
        obj.disc = disc; 
        obj.float = float; 
        obj.guide = guide; 
        obj.module = md; 
        obj.oring = oring; 
        obj.rubber_kit = rubber_kit; 
        obj.seat = seat; 
        obj.spring = spring; 
        return obj; 
    }
    function packXVBA() {
        let obj = {};
        obj.did_not_open = ai_did_not_open; 
        obj.opened_at = ai_opened_at;
        obj.opened_fully = ai_opened_fully; 
        obj.check_valve = check_valve; 
        obj.final_did_not_open = final_ai_did_not_open; 
        obj.final_opened_at = final_ai_opened_at; 
        obj.final_opened_fully = final_ai_opened_fully; 
        obj.final_check_valve = final_check_valve; 
        obj.final_check_valve_failed = final_check_valve_failed; 
        return obj; 
    }
    function packReliefValve() {
        let obj = {}; 
        obj.opened_at = rp_opened_at; 
        obj.final_opened_at = final_rp_opened_at; 
        obj.pressdrop = pressdrop; 
        obj.final_pressdrop = final_pressdrop; 
        obj.did_not_open = rp_did_not_open; 
        obj.final_rp_did_not_open = final_rp_did_not_open; 
        obj.typeII = rp_typeII; 
        return obj; 
    }
    function packAssembly() {
        let obj = {}
        obj.serial = serial_number; 
        obj.location = location; 
        obj.size = size; 
        obj.make = make; 
        obj.model = model; 
        obj.hazard = hazard; 
        obj.type = type; 
        return obj
    }
    function packApproved() {
        let obj = {}; 
        obj.airgap = airgap; 
        obj.assembly = assembly; 
        obj.installation = installation; 
        obj.orientation = orientation; 
        obj.usc = usc; 
        return obj; 
    }
    function packAirgap() {
        let obj = {}; 
        obj.pipesize = pipesize;
        obj.physical_separation = physical_separation;
        return obj; 
    }
    function packDoubleCheck() {
        let obj = {}; 
        obj.check1 = check1; 
        obj.check1_tight = check1_tight; 
        obj.check1_leaked = check1_leaked; 
        obj.check2 = check2; 
        obj.check2_tight = check2_tight; 
        obj.check2_leaked = check2_leaked; 

        obj.fcheck1 = finalCheck1;
        obj.fcheck1_tight = fcheck1_tight;
        obj.fcheck1_leaked = fcheck1_leaked;
        obj.fcheck2 = finalCheck2;
        obj.fcheck2_tight = fcheck2_tight;
        obj.fcheck2_leaked = fcheck2_leaked; 
        obj.typeII = dc_typeII; 
        return obj; 

    }

    const save = (complete) => {
        return new Promise ( async(resolve, reject) =>{
            console.log("Saving results: ", complete)
            setSaving(true)
            let promises = []; 
            promises.push(updateAssembly(assemblyID, packAssembly())) 
            promises.push(updateApproved(reportID, packApproved()))
            promises.push(updateSystem(reportID, packSystem()))
            promises.push(updateParts(reportID, packParts()))
            promises.push(updateRemarks(reportID, packRemarks()))
            promises.push(updateGaugeDetails(reportID))
            promises.push(updateSubmitter(reportID))
            if(complete){
                promises.push( updateServiceStatus(reportID ) )
            }
            
            switch (testType) {
                case "NEW":
                case "E":
                    switch (type) {
                        case "DC":
                        case "DCDA":
                        case "DCDAII":
                            promises.push(updateDoubleCheck(reportID, packDoubleCheck() ))
                            break;
                        case "RP":
                        case "RPDA":
                        case "RPDAII":
                            promises.push(updateReliefValve(reportID,  packReliefValve() ))
                            break;
                        case "AVB":
                        case "SVB":
                        case "PVB":
                            promises.push(updateXVBA(reportID, packXVBA() ))
                            break;
                        case "AG":
                            promises.push(updateAirgap(reportID, packAirgap() ))
                            break;
                        default:
                            break;
                    }
                    break;
                case "REPLACED":
                case "RM":
                    promises.push(updateRemoved(reportID, packRemoved() ) )
                    break;
                default:
                    break; 
               }
            Promise.all(promises).then((data, err) => {
               
                clearReducers()
                console.log("SAVED")
            })
        })
    }


    const clearReducers = async () => {
        let arr = []
        arr.push( dispatch( clearAssembly() ) ) 
        arr.push( dispatch( clearApproved() ) ) 
        arr.push( dispatch( clearAG() ))
        arr.push( dispatch( clearDoubleCheck() ) )
        arr.push( dispatch( clearFinalResults() ) )
        arr.push( dispatch( clearInitialResults() ) ) 
        arr.push( dispatch( clearParts() ) )
        arr.push( dispatch( clearRemarks()) )
        arr.push( dispatch( clearAll()) ) 
        arr.push( dispatch( clearRP() ))
        arr.push(dispatch(clearSystem()))
        arr.push(dispatch(clearXVBA())) 
        arr.push( dispatch(clearPad() ))
        Promise.all(arr).then((data, err) => {
            dispatch(clearReportID()) 
            dispatch(clearAssemblyID()) 
            dispatch( requestStopServices( stopID) )
             setSaving(false)
            setShow(false)
        })
    }

   return (
       <>
       {
        showNotTested ?
            <NotTested closeModal = {() => { setShowNotTested(false) }} />
            : 
            <>
                
            </>
       }
       <Modal show={show}  fullscreen >
           <Modal.Header closeButton onHide={
                () => {
                    save(false)
                }
             }>
           </Modal.Header>
           <Modal.Body>
                <MyBody />
           </Modal.Body>
           <Modal.Footer>
              
                          
                           <Button
                               variant="success"
                               onClick={
                                   ()=>{
                                    save(true)
                                   }
                               }
                           >
                                {
                                    saving ? 
                                        <Spinner />
                                    : 
                                       <> Mark as complete</>
                                }
                                
                           </Button>
             
               
           </Modal.Footer>
       </Modal>
    </> 
  ) 
}