import {
    SET_CHECK_FAILED, CLEAR_CHECK_FAILED
} from "../../actions/xvba";

const _null = null;

export default (state = _null, { type, truefalse }) => {
    Object.freeze(state);
    switch (type) {
        case SET_CHECK_FAILED:
            return truefalse;
        case CLEAR_CHECK_FAILED:
            return _null
        default:
            return state;
    }
};