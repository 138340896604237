
import * as server from "../util/invoiceServer";

export const SET_INVOICE_ID = "SET_INVOICE_ID"
export const CLEAR_INVOICE_ID= "CLEAR_INVOICE_ID"
export const SET_ITEM_ID = "SET_ITEM_ID"
export const CLEAR_ITEM_ID= "CLEAR_ITEM_ID"
export const SET_ITEM_LIST = "SET_ITEM_LIST"
export const CLEAR_ITEM_LIST = "CLEAR_ITEM_LIST"
export const SET_OPTIONS_LIST = "SET_OPTIONS_LIST"
export const CLEAR_OPTIONS_LIST = "CLEAR_OPTIONS_LIST"
export const SET_BILLING_ID = "SET_BILLING_ID"
export const  CLEAR_BILLING_ID = "CLEAR_BILLING_ID"



const setInvoiceID = (id) => ({
	type: SET_INVOICE_ID, 
	id
})
const setItemID = (id) => ({
	type: SET_ITEM_ID, 
	id
})
const setItemList= (list) => ({
	type: SET_ITEM_LIST, 
	list
})
const setOptionsList= (list) => ({
	type: SET_OPTIONS_LIST, 
	list
})
const setBillingID= (id) => ({
	type: SET_BILLING_ID, 
	id
})

const nullInvoiceID = () => ({
	type: CLEAR_INVOICE_ID, 
})
const nullBillingID = () => ({
	type: CLEAR_BILLING_ID, 
})
const nullItemID = () => ({
	type: CLEAR_ITEM_ID, 
})
const emptyItemList = () => ({
	type: CLEAR_ITEM_LIST, 
})
const emptyOptionsList = () => ({
	type: CLEAR_OPTIONS_LIST, 
})


export const determineInvoiceID = (id) => dispatch => {
	return new Promise(async( resolve, reject) =>{
		dispatch( setInvoiceID(id) )
		resolve() 
	})
}

export const determineBillingID = (id) => dispatch => {
	return new Promise(async( resolve, reject) =>{
		dispatch( setBillingID(id) )
		resolve() 
	})
}
export const determineItemID = (id) => dispatch => {
	return new Promise(async( resolve, reject) =>{
		dispatch( setItemID(id) )
		resolve() 
	})
}

export const clearInvoiceID = () => dispatch => {
	return new Promise(async( resolve, reject) =>{
		dispatch( nullInvoiceID() )
		resolve() 
	})
}

export const clearBillingID = () => dispatch => {
	return new Promise(async( resolve, reject) =>{
		dispatch( nullBillingID() )
		resolve() 
	})
}
export const clearItemID = () => dispatch => {
	return new Promise(async( resolve, reject) =>{
		dispatch( nullItemID() )
		resolve() 
	})
}

export const clearItemList = () => dispatch => {
	return new Promise(async( resolve, reject) =>{
		dispatch( emptyItemList() )
		resolve() 
	})
}
export const clearOptionsList = () => dispatch => {
	return new Promise(async( resolve, reject) =>{
		dispatch( emptyOptionsList() )
		resolve() 
	})
}



export const requestItemList = (invoiceID) => dispatch =>{
	return new Promise(async(resolve, reject) =>{
		const response = await server.requestItemList(invoiceID); 
		const data = await response.json()
		if(response.ok) {
			dispatch( setItemList( JSON.parse(data).list )) ; 
			resolve()
		} 
	}) 
}

export const requestOptionsList = () => dispatch =>{
	return new Promise(async(resolve, reject) =>{
		const response = await server.requestOptionsList(); 
		const data = await response.json()
		if(response.ok) {
			dispatch( setOptionsList( JSON.parse(data).list )) ; 
			resolve()
		} 
	}) 
}

export const requestInvoiceID = (stopID) => dispatch => {
	return new Promise(async (resolve, reject) => {
		const response = await server.requestInvoiceID(stopID); 
		const data = await response.json();
		if (response.ok) {
			dispatch(setInvoiceID(JSON.parse(data).id) );
			resolve()
		}
	})
}


export const requestInvoiceDetails = (invoiceID) => {
	return new Promise(async(resolve, reject) =>{
		const response = await server.requestInvoiceDetails(invoiceID); 
		const data = await response.json()
		if(response.ok) {
			resolve( JSON.parse(data).obj ) ; 

		} 
	}) 
}

export const requestItemDetails = (itemID) =>{
	return new Promise(async(resolve, reject) =>{
		const response = await server.requestItemDetails(itemID); 
		const data = await response.json()
		if(response.ok) {
			resolve( JSON.parse(data).obj ) ; 
		} 
	}) 
}

export const requestBillingDetails = (invoiceID) => {
	return new Promise(async(resolve, reject) =>{
		const response = await server.requestBillingDetails(invoiceID); 
		const data = await response.json()
		if(response.ok) {
			resolve( JSON.parse(data).obj ) ; 
		} 
	}) 
}

export const requestServiceDetails = (addressID) => {
	return new Promise(async(resolve, reject) =>{
		const response = await server.requestServiceDetails(addressID); 
		const data = await response.json()
		if(response.ok) {
			resolve( JSON.parse(data).obj ) ; 
		} 
	}) 
}

export const createLineItem = (invoiceID) => {
	return new Promise(async(resolve, reject) =>{
		const response = await server.createLineItem(invoiceID); 
		const data = await response.json()
		if(response.ok) {
			resolve( JSON.parse(data).obj ) ; 
		} 
	}) 
}

export const removeLineItem = (itemID) => {
	return new Promise(async(resolve, reject) =>{
		const response = await server.removeLineItem(itemID); 
		const data = await response.json()
		if(response.ok) {
			resolve( JSON.parse(data).obj ) ; 
		} 
	}) 

}

export const updateLineItem = (id, obj) => {
	return new Promise(async(resolve, reject) =>{
		const response = await server.updateLineItem(id, obj); 
		const data = await response.json()
		if(response.ok) {
			resolve( JSON.parse(data).obj ) ; 
		} 
	}) 
}

export const updateBillingDetails = (id, obj) => {
	return new Promise(async(resolve, reject) =>{
		const response = await server.updateBillingDetail(id, obj); 
		const data = await response.json()
		if(response.ok) {
			resolve( JSON.parse(data).obj ) ; 
		} 
	}) 
}
export const updateServiceDetails = (id, obj) => {
	return new Promise(async(resolve, reject) =>{
		const response = await server.updateServiceDetail(id, obj); 
		const data = await response.json()
		if(response.ok) {
			resolve( JSON.parse(data).obj ) ; 
		} 
	}) 
}


export const voidInvoice = (id) => {
	return new Promise(async(resolve, reject) =>{
		const response = await server.voidInvoice(id); 
		const data = await response.json()
		if(response.ok) {
			resolve() ; 
		} 
	}) 
}
export const unvoidInvoice = (id) => {
	return new Promise(async(resolve, reject) =>{
		const response = await server.unvoidInvoice(id); 
		const data = await response.json()
		if(response.ok) {
			resolve() ; 
		} 
	}) 
}

export const updateCheckNumber= (id) => {
	return new Promise(async(resolve, reject) =>{
		const response = await server.updateCheckNumber(id);  
		const data = await response.json()
		if(response.ok) {
			resolve(JSON.parse(data).generated) ; 
		} 
	}) 
}

export const getCheckNumber= (id) => {
	return new Promise(async(resolve, reject) =>{
		const response = await server.getCheckNumber(id); 
		const data = await response.json()
		if(response.ok) {
			console.log("data: ", data)
			resolve(JSON.parse(data).checkNumber) ; 
		} 
	}) 
}

export const getInvoiceStatus = (id) => {
	return new Promise(async(resolve, reject) =>{
		const response = await server.getInvoiceStatus(id); 
		const data = await response.json()
		if(response.ok) {
			console.log("data: ", data)
			resolve(JSON.parse(data).status) ; 
		} 
	}) 
} 

