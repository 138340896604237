import {
    SET_REPAIRED, CLEAR_REPAIRED
} from "../../actions/finalResults";

const _null = false;

export default (state = _null, { type, truefalse }) => {
    Object.freeze(state);
    switch (type) {
        case SET_REPAIRED:
            return truefalse;
        case CLEAR_REPAIRED:
            return _null
        default:
            return state;
    }
};