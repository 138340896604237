import LoginForm from "../modules/LoginForm"; 
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Footer from "../modules/Footer"; 

export default function Login(){
	return(
		<Container bsPrefix ="spaced-body">
			<div className="header">
				<h1 className="companyName"> American Backflow & <br/> Plumbing Services; Inc </h1>
			</div>
			<Row bsPrefix="main-content">
			<Container bsPrefix="center-form">
				<LoginForm />
			</Container>
			</Row>
			<Row>
				<Footer />
			</Row>
		</Container>
	); 
}