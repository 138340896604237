export const updateSubmitter = async (id) => (
	fetch("backflow/update/updateSubmitter", {
		method: "PUT",
		body: JSON.stringify({ id: id }),
		headers: {
			"Content-Type": "application/json"
		}
	})
);


export const updateGaugeDetails = async (id) => (
	fetch("backflow/update/gaugeDetails", {
		method: "PUT",
		body: JSON.stringify({ id: id }),
		headers: {
			"Content-Type": "application/json"
		}
	})
);
export const updateAssembly = async (id, obj) => (
	fetch("backflow/update/assembly", {
		method: "PUT",
		body: JSON.stringify({ id: id, obj:obj}),
		headers: {
			"Content-Type": "application/json"
		}
	})
);


export const updateServiceStatus = async (id) => (
	fetch("backflow/update/serviceStatus", {
		method: "PUT",
		body: JSON.stringify({ id: id}),
		headers: {
			"Content-Type": "application/json"
		}
	})
);
export const updateApproved = async (id, obj) => (
	fetch("backflow/update/approved", {
		method: "PUT",
		body: JSON.stringify({ id: id, obj: obj }),
		headers: {
			"Content-Type": "application/json"
		}
	})
);

export const updateSystem = async (id, obj) => (
	fetch("backflow/update/system", {
		method: "PUT",
		body: JSON.stringify({ id: id, obj: obj }),
		headers: {
			"Content-Type": "application/json"
		}
	})
);

export const updateParts = async (id, obj) => (
	fetch("backflow/update/parts", {
		method: "PUT",
		body: JSON.stringify({ id: id, obj: obj }),
		headers: {
			"Content-Type": "application/json"
		}
	})
);

export const updateRemarks = async (id, obj) => (
	fetch("backflow/update/remarks", {
		method: "PUT",
		body: JSON.stringify({ id: id, obj: obj }),
		headers: {
			"Content-Type": "application/json"
		}
	})
);

export const updateDoubleCheck = async (id, obj) => (
	fetch("backflow/update/DoubleCheck", {
		method: "PUT",
		body: JSON.stringify({ id: id, obj: obj }),
		headers: {
			"Content-Type": "application/json"
		}
	})
);
export const updateReliefValve = async (id, obj) => (
	fetch("backflow/update/ReliefValve", {
		method: "PUT",
		body: JSON.stringify({ id: id, obj: obj }),
		headers: {
			"Content-Type": "application/json"
		}
	})
);

export const updateXVBA = async (id, obj) => (
	fetch("backflow/update/XVBA", {
		method: "PUT",
		body: JSON.stringify({ id: id, obj: obj }),
		headers: {
			"Content-Type": "application/json"
		}
	})
);

export const updateAG = async (id, obj) => (
	fetch("backflow/update/AG", {
		method: "PUT",
		body: JSON.stringify({ id: id, obj: obj }),
		headers: {
			"Content-Type": "application/json"
		}
	})
);

export const updateRemoved = async (id, obj) => (
	fetch("backflow/update/removed", {
		method: "PUT",
		body: JSON.stringify({ id: id, obj: obj }),
		headers: {
			"Content-Type": "application/json"
		}
	})
);
