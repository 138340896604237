import * as server from "../util/mapServer";
export const SET_WAYPOINTS = "SET_WAYPOINTS"
export const SET_ROUTED = "SET_ROUTED"
export const CLEAR_ROUTED = "CLEAR_ROUTED"
export const CLEAR_WAYPOINTS = "CLEAR_WAYPOINTS"
export const SET_START_POINT = "SET_START_POINT"
export const SET_END_POINT = "SET_END_PONT"
export const CLEAR_START_POINT = "CLEAR_START_POINT"
export const CLEAR_END_POINT = "CLEAR_END_POINT"
export const SET_BACKFLOW_MAP = "SET_BACKFLOW_MAP"
export const CLEAR_BACKFLOW_MAP = "CLEAR_BACKFLOW_MAP"
export const SET_LEGS = "SET_LEGS"
export const CLEAR_LEGS = "CLEAR_LEGS"
export const SET_ROUTE = "SET_ROUTE"
export const SET_DISTANCE_MATRIX = "SET_DISTANCE_MATRIX"
export const CLEAR_DISTANCE_MATRIX = "CLEAR_DISTANCE_MATRIX"
export const SET_TIME_WINDOWS= "SET_TIME_WINDOWS"
export const CLEAR_TIME_WINDOWS= "CLEAR_TIME_WINDOWS"
export const SET_ORDER = "SET_ORDER"
export const CLEAR_ORDER = "CLEAR_ORDER"
export const SET_SERVICES_TIMES = "SET_SERVICES_TIMES"
export const CLEAR_SERVICES_TIMES = "CLEAR_SERVICES_TIMES"

const setServiceTimes = (list) => ({
	type: SET_SERVICES_TIMES, 
	list
})

const clearServiceTimes = () => ({
	type: CLEAR_SERVICES_TIMES
})

const setRouted = (truefalse) => ({
	type: SET_ROUTED, 
	truefalse
})

const clearRouted = () => ({
	type: CLEAR_ROUTED
})

const setOrder = (list) => ({
	type: SET_ORDER, 
	list
})


const clearOrder = () => ({
	type: CLEAR_ORDER
})



const setRoute = (truefalse) => ({
	type: SET_ROUTE, 
	truefalse

})

const setTimeWindows = (list) => ({
	type: SET_TIME_WINDOWS, 
	list

})

const setDistanceMatrix = (graph) => ({
	type: SET_DISTANCE_MATRIX, 
	graph

})

const clearTimeWindows = () => ({
	type: CLEAR_TIME_WINDOWS
})

const clearDistanceMatrix = () => ({
	type: CLEAR_DISTANCE_MATRIX
})


const setMap = (obj) =>({
	type: SET_BACKFLOW_MAP, 
	obj
})
const setLegs = (list) =>({
	type: SET_LEGS, 
	list
})

const setWaypoints  = (list) =>({
	type: SET_WAYPOINTS, 
	list
})
const clearWaypoints  = () =>({
	type: CLEAR_WAYPOINTS
})

const setStartPoint  = (obj) =>({
	type: SET_START_POINT, 
	obj
})
const setEndPoint  = (obj) =>({
	type: SET_END_POINT, 
	obj
})

const clearEndPoint = () =>({
	type: CLEAR_END_POINT
})
const clearStartPoint = () =>({
	type: CLEAR_START_POINT
})

export const clearMap = () => dispatch => {
	return new Promise( async(resolve, reject) =>{
		let promises = []
		promises.push( dispatch(clearStartPoint() ) )
		promises.push( dispatch(clearEndPoint() ) ) 
		promises.push( dispatch(clearWaypoints() ) )
		Promise.all(promises).then((data, err) =>{	
			resolve()
		})  
	})
}


export const reduceRouted= (truefalse) => dispatch => {
	return new Promise(async(resolve, reject) =>{
		dispatch( setRouted(truefalse))
		resolve()
	})
}

export const clearIsRouted= () => dispatch => {
	return new Promise(async(resolve, reject) =>{
		dispatch( clearRouted())
		resolve()
	})
}


export const reduceRoute= () => dispatch => {
	return new Promise(async(resolve, reject) =>{
		dispatch( setRoute(true))
		resolve()
	})
}


export const reduceLegs = (list) => dispatch => {
	return new Promise(async(resolve, reject) =>{
		dispatch(setLegs(list) )
		resolve()
	})
}

export const reduceMap= (date) => dispatch => {
	return new Promise(async(resolve, reject) =>{
		resolve()
	})
}

export const reduceStartPoint = (obj) => dispatch => {
	return new Promise(async(resolve, reject) =>{
		dispatch( setStartPoint(obj) ) 
		resolve()
	})
}

export const clearStart = () => dispatch => {
	return new Promise(async(resolve, reject) =>{
		dispatch( clearStartPoint() ) 
		resolve()
	})
}
export const reduceEndPoint = (obj) => dispatch => {
	return new Promise(async(resolve, reject) =>{
		dispatch( setEndPoint(obj) ) 
		resolve()
	})
}

export const reduceWaypoints = (arr) => dispatch => {
	return new Promise(async(resolve, reject) =>{
		dispatch( setWaypoints(arr) ) 
		resolve()
	})
}

export const reduceDistanceMatrix = (graph) => dispatch => {
	return new Promise(async(resolve, reject) =>{
		dispatch( setDistanceMatrix(graph) ) 
		resolve()
	})
}

export const clearDM = () => dispatch => {
	return new Promise(async(resolve, reject) =>{
		dispatch( clearDistanceMatrix() ) 
		resolve()
	})
}

export const reduceTimeWindows = (list) => dispatch => {
	return new Promise(async(resolve, reject) =>{
		dispatch( setTimeWindows(list) ) 
		resolve()
	})
}

export const clearTW = () => dispatch => {
	return new Promise(async(resolve, reject) =>{
		dispatch( clearTimeWindows() ) 
		resolve()
	})
}

export const reduceOrder = (arr) => dispatch => {
	return new Promise(async(resolve, reject) =>{
		dispatch( setOrder(arr) ) 
		resolve()
	})
}
export const clearOrd = () => dispatch => {
	return new Promise(async(resolve, reject) =>{
		dispatch( clearOrder() ) 
		resolve()
	})
}

export const requestStopServiceTime = (stopID) => {
	return new Promise(async(resolve, reject) =>{
		//console.log("Requesting this stop: ", stopID)
		const response = await server.requestStopServiceTime(stopID);
		const data = await response.json()
		if(response.ok){
			//console.log("Resoolving this time: ", JSON.parse(data).time)
			resolve(JSON.parse(data).time)
		}
		
	})

}

export const reduceServiceTimes = (arr) => dispatch => {
	return new Promise(async(resolve, reject) =>{
		dispatch( setServiceTimes(arr) ) 
		resolve()
	})
}






