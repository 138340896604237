export const SET_FINAL_PASS = "SET_FINAL_PASS"
export const SET_FINAL_FAIL = "SET_FINAL_FAIL"
export const SET_REPAIRED = "SET_REPAIRED"
export const SET_CLEANED = "SET_CLEANED"

export const CLEAR_FINAL_PASS = "CLEAR_FINAL_PASS"
export const CLEAR_FINAL_FAIL = "CLEAR_FINAL_FAIL"
export const CLEAR_REPAIRED = "CLEAR_REPAIRED"
export const CLEAR_CLEANED = "CLEAR_CLEANED"

const clearFinalPass = () => ({ type: CLEAR_FINAL_PASS })
const clearFinalFail = () => ({ type: CLEAR_FINAL_FAIL })
const clearRepaired = () => ({ type: CLEAR_REPAIRED })
const clearCleaned = () => ({ type: CLEAR_CLEANED })
export const clearFinalResults = () => dispatch => {
    return new Promise((resolve, reject) => {
        let p1 = dispatch(clearFinalFail())
        let p2 = dispatch(clearFinalPass())
        let p3 = dispatch(clearRepaired())
        let p4 = dispatch(clearCleaned())
        Promise.all([p1, p2, p3, p4]).then((data, err) => {
            resolve()
        })
    })
}

const setFinalPass = (truefalse) => ({ type: SET_FINAL_PASS, truefalse })
const setFinalFail = (truefalse) => ({ type: SET_FINAL_FAIL, truefalse })
const setRepaired = (truefalse) => ({ type: SET_REPAIRED, truefalse })
const setCleaned = (truefalse) => ({ type: SET_CLEANED, truefalse })


export const reduceFinalPass = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setFinalPass(truefalse))
        resolve()
    })
}
export const reduceFinalFail = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setFinalFail(truefalse))
        resolve()
    })
}
export const reduceRepaired = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setRepaired(truefalse))
        resolve()
    })
}
export const reduceCleaned = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setCleaned(truefalse))
        resolve()
    })
}