/* eslint-disable import/no-anonymous-default-export */
import {
  SET_REPORT_ID, CLEAR_REPORT_ID
} from "../../actions/stopsApp";


const _nullID = null; 
             
export default (state = _nullID, { type, id }) => {
  Object.freeze(state);
  switch (type) {
    case SET_REPORT_ID:
      return id;
    case CLEAR_REPORT_ID: 
      return _nullID
    default:
      return state;
  }
};