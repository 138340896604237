import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import InputGroup from 'react-bootstrap/InputGroup';
import Alert from 'react-bootstrap/Alert';
import { useState, useEffect, useRef, useCallback} from "react";
import NumberPad from "./NumberPad"
import {useSelector, useDispatch} from "react-redux";
import { updateTargetElement, resetUpdatedValue }  from "../actions/NumberPad"

import { getTestReport, updateRepairs, updateFinalCheck2, updateFinalCheck1, updateFDoubleCheck
} from "../actions/report"

export default function FinalDCForm(){
	const [disc, setDisc] = useState(null); 
	const [spring, setSpring] = useState(null); 
	const [guide, setGuide] = useState(null); 
	const [diaph, setDiaph] = useState(null); 
	const [seat, setSeat] = useState(null); 
	const [o_ring, setO_ring]= useState(null); 
	const [modules, setModules] = useState(null); 
	const [rubber_kit, setRubberKit] = useState(null); 
	const [air_inlet_disc, setAirInletDisc] = useState(null); 
	const [air_inlet_spring, setAirInletSpring] = useState(null); 
	const [check_disc, setCheckDisc] = useState(null); 
	const [check_spring, setCheckSpring] = useState(null); 
	const [float, setFloat] = useState(null); 
	const report = useSelector((state) => state.report)
	const reportID = useSelector((state) => state.report_id)
	const final_check1 = useSelector((state) => state.final_check1); 
	const final_check2 = useSelector( (state) => state.final_check2)
	const [comment, setComment] = useState(null)
	const [repaired, setRepaired] = useState(false); 
	const [cleaned, setCleaned] = useState(false) ;

	const [allowInput, setAllowInput] = useState( ( repaired || cleaned))

	const dispatch = useDispatch()  
	const [check1_tight, setCheck1Tight] = useState(false)
	const [check2_tight, setCheck2Tight] = useState(false)
	const [finalPass, setFinalPass] = useState(false)
	const [finalFail, setFinalFail] = useState(false)

	useEffect(()=>{
		setAllowInput( (repaired || cleaned) )
	}, [repaired, cleaned])

	useEffect(()=>{
		if(final_check1 >= 1.0){
			setCheck1Tight(true)
		}else{
			setCheck1Tight(false)
		}

		if(final_check2 >= 1.0){
			setCheck2Tight(true)
		}else{
			setCheck2Tight(false)
		}

	}, [final_check1, final_check2])

	useEffect(()=>{
		if(report != null){
			setCleaned(report.cleaned)
			setRepaired(report.repaired)
			setDisc(report.disc); 
			setSpring(report.spring); 
			setGuide(report.guide); 
			setDiaph(report.diaphragm); 
			setModules(report.module); 
			setSeat(report.seat); 
			setO_ring(report.o_ring); 
			setRubberKit( report.rubber_kit); 
			setAirInletSpring(report.air_spring); 
			setAirInletDisc(report.air_disc); 
			setCheckDisc(report.check_disc); 
			setCheckSpring(report.check_spring); 
			setFloat(report.ff)
			dispatch( updateFinalCheck1(report.repair_rpa_double_c1_psid) )
			dispatch( updateFinalCheck2(report.repair_rpa_double_c2_psid) )
			setFinalPass(report.repair_pass)
			setFinalFail(report.repair_fail)
			document.querySelector("#final_pass").checked = report.repair_pass
			document.querySelector("#final_fail").checked = report.repair_fail
		}
	}, [report])


	const redirectKeyboard = (event)=>{
		dispatch( updateTargetElement(event.target.id) ).then((data, err) =>{
			if(err){
				console.log(err)
			}
		})
	}


	return(
		<Container fluid>
			<Row>
				<Col>	
					<hr />
					<strong> This Device Was: </strong>
				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Check id = "cleaned" type="checkbox" label="Repaired" checked = {repaired} onChange= { (event)=>{ setRepaired(event.target.checked)}}/>
				</Col>
				<Col>
					<Form.Check id = "repaired" type ="checkbox" label ="Cleaned"  checked = {cleaned} onChange= { (event)=>{ setCleaned(event.target.checked)}}/>
				</Col>
			</Row>
			<Row>
				<Col>
					<hr/>
					<strong>Parts</strong>
				</Col>
			</Row>
			<Row xs = {2} sm md  lg = {3}>

				<Col>
					<Form.Check 
						id="disc" 
						label ="Disc" 
						checked = {disc} 
						onChange= { (event)=>{ setDisc(event.target.checked)}}
						disabled = {!allowInput}
					/>
				</Col>
				<Col>
					<Form.Check id ="spring" label ="Spring" checked = {spring} onChange= { (event)=>{ setSpring(event.target.checked)}} disabled = {!allowInput}/>
				</Col>
				<Col>
					<Form.Check id ="guide" label ="Guide" checked = {guide} onChange= { (event)=>{ setGuide(event.target.checked)}} disabled = {!allowInput}/>
				</Col>
				<Col>
					<Form.Check id ="diaph" label ="Diaphragm" checked = {diaph} onChange= { (event)=>{ setDiaph(event.target.checked)}} disabled = {!allowInput}/>
				</Col>
				<Col>
					<Form.Check  id ="seat" label ="Seat" checked = {seat} onChange= { (event)=>{ setSeat(event.target.checked)}} disabled = {!allowInput}/>
				</Col>
				<Col>
					<Form.Check id ="o_ring" label ="O-Ring(s)" checked = { o_ring} onChange= { (event)=>{ setO_ring(event.target.checked)}} disabled = {!allowInput}/>
				</Col>	
				<Col>
					<Form.Check id ="module" label ="Module" checked = {modules} onChange= { (event)=>{ setModules(event.target.checked)}} disabled = {!allowInput}/>
				</Col>
				<Col>

					<Form.Check id ="rubber_kit" label ="Rubber Kit" checked={rubber_kit} onChange= { (event)=>{ setRubberKit(event.target.checked)}} disabled = {!allowInput}/>
				</Col>
				<Col>
					<Form.Check id ="air_disc" label ="Air Inlet Disc" checked = { air_inlet_disc} onChange= { (event)=>{ setAirInletDisc(event.target.checked)}} disabled = {!allowInput}/>
				</Col>
				<Col>
					<Form.Check id ="air_spring" label ="Air Inlet Spring" checked={air_inlet_spring} onChange= { (event)=>{ setAirInletSpring(event.target.checked)}} disabled = {!allowInput}/>
				</Col>
				<Col>
					<Form.Check id ="check_disc" label ="Check Disc" checked = {check_disc} onChange= { (event)=>{ setCheckDisc(event.target.checked)}} disabled = {!allowInput}/>
				</Col>
				<Col>
					<Form.Check id ="check_spring" label ="Check Spring" checked = { check_spring} onChange= { (event)=>{ setCheckSpring(event.target.checked)}} disabled = {!allowInput}/>
				</Col>
				<Col>
					<Form.Check  id ="float" label ="Float" checked = {float} onChange= { (event)=>{ setFloat(event.target.checked)}} disabled = {!allowInput}/>
				
				</Col>
			</Row>
			<Row>
				<Col>
					<hr/>
					<strong>Final Double Check </strong>
				</Col>
			</Row>
			<Row xs = {1}>
				<Col>
					<InputGroup>
						<InputGroup.Text> #1 </InputGroup.Text>
						<Form.Control 
							id="final_check1"
							value = {final_check1}
							onClick = { redirectKeyboard }
							readOnly = {true}
							disabled = {!allowInput}
						/>
						{
							check1_tight ?
								<Badge bg = "success"> Tight </Badge>
							: 
								<Badge bg = "secondary"> Leaked </Badge>
						}

					</InputGroup>
				</Col>
				<Col>
					<InputGroup>
						<InputGroup.Text> #2 </InputGroup.Text>
						<Form.Control 
							id="final_check2"
							value = {final_check2}
							onClick = { redirectKeyboard }
							readOnly = {true}
							disabled = {!allowInput}
						/>
						{
							check2_tight ?
								<Badge bg = "success"> Tight </Badge>
							: 
								<Badge bg = "secondary"> Leaked </Badge>
						}
					</InputGroup>
				</Col>
			</Row>
			<Row>
				<Col xs = {4}>
					<Container fluid>

							<hr/>
							<Row>

								<Col>
									<Form.Check 
									id = "final_pass" 
									label = "PASSED" 
									type = "radio" 
									checked = {finalPass} 
									onChange = {(event) => {
										setFinalFail(!event.target.checked)
										setFinalPass(event.target.checked)
									}}
									disabled = {!allowInput}
								/>
								</Col>
								<Col>
									
									<Form.Check 
										id = "final_fail" 
										label = "FAILED"  
										type="radio" 
										checked = {finalFail} 
										onChange = {(event) => {
											setFinalFail(event.target.checked)
											setFinalPass(!event.target.checked)
										}} 
										disabled = {!allowInput}
									/>
								</Col>

							</Row>
							
						</Container>
				</Col>
				<Col xs= {8}>
					<hr/>
					<NumberPad />
				</Col>
			</Row>
		</Container>
	)
}