export const requestItemList =  (invoiceID) =>(
	fetch("backflow/invoiceApp/itemlist?" + new URLSearchParams({
		invoiceID: invoiceID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
);

export const requestOptionsList = () => (
	fetch("backflow/invoiceApp/optionslist" , {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const requestInvoiceDetails = (invoiceID) => (
	fetch("backflow/invoiceApp/invoicedetails?" + new URLSearchParams({
		invoiceID: invoiceID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 

)
export const requestItemDetails = (itemID) => (
	fetch("backflow/invoiceApp/itemdetails?" + new URLSearchParams({
		itemID: itemID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 

)

export const requestInvoiceID = (stopID)=>(
	fetch("backflow/invoiceApp/invoiceID?" + new URLSearchParams({
		stopID: stopID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 

)
export const requestBillingDetails = (invoiceID) => (
	fetch("backflow/invoiceApp/billingdetails?" + new URLSearchParams({
		invoiceID: invoiceID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 

)

export const requestServiceDetails = (addressID) => (
	fetch("backflow/invoiceApp/serviceaddress?" + new URLSearchParams({
		addressID: addressID
	}), {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const createLineItem = (id) => (
	fetch("backflow/invoiceApp/createlineitem" , {
		method: "POST", 
		body : JSON.stringify( { invoiceID: id} ),
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)
export const removeLineItem = (itemID) => (
	fetch("backflow/invoiceApp/deletelineitem" , {
		method: "POST", 
		body : JSON.stringify( { itemID: itemID} ),
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const updateLineItem = (id, obj) => (
	fetch("backflow/invoiceApp/updateLineItem" , {
		method: "PUT", 
		body : JSON.stringify( { itemID: id, obj: obj} ), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const updateServiceDetail = (id, obj) => (
	fetch("backflow/invoiceApp/updateServiceDetail" , {
		method: "PUT", 
		body : JSON.stringify( { addressID: id, obj: obj} ), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const voidInvoice = (id) => (
	fetch("backflow/invoiceApp/void" , {
		method: "PUT", 
		body : JSON.stringify( { invoiceID: id} ), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)


export const unvoidInvoice = (id) => (
	fetch("backflow/invoiceApp/unvoid" , {
		method: "PUT", 
		body : JSON.stringify( { invoiceID: id} ), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const updateCheckNumber = (id, checkNumber) => (
	fetch("backflow/invoiceApp/checkNumber" , {
		method: "PUT", 
		body : JSON.stringify( { invoiceID: id } ), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const getCheckNumber = (id) => (
	fetch("backflow/invoiceApp/checkNumber?" + new URLSearchParams({
		invoiceID: id
	}) , {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)


export const getInvoiceStatus = (id) => (
	fetch("backflow/invoiceApp/invoiceStatus?" + new URLSearchParams({
		invoiceID: id
	}) , {
		method: "GET", 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)

export const updateBillingDetail = (id, obj) => (
	fetch("backflow/invoiceApp/updateBillingDetail" , {
		method: "PUT", 
		body : JSON.stringify( { billingID: id, obj: obj} ), 
		headers: {
            "Content-Type" : "application/json"
        }
	}) 
)



