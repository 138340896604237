import React from 'react'
import {Navigate, useLocation, useNavigate, redirect, Outlet} from "react-router-dom"; 
import {useSelector, useDispatch} from "react-redux";
import { connect } from "react-redux";
import ActivateAccount from "../Pages/ActivateAccount"

const mapStateToProps = ({ session }) => ({
  session
});

const ProtectedRoutes = ( { session }) => {

	if(session.username != null && session.activated){
		return <Outlet />
	}else if(session.username != null  && !session.activated){
		return <ActivateAccount />
	}else{
		return <Navigate to='/login' />; 
	}

}

export default connect(
  mapStateToProps,
)(ProtectedRoutes);