export const SET_AI_OPENED_AT = "SET_AI_OPENED_AT"
export const SET_CHECK_VALVE = "SET_CHECK_VALVE"
export const SET_AI_OPENED_FULLY = "SET_AI_OPENED_FULLY"
export const SET_CHECK_FAILED = "SET_CHECK_FAILED"
export const SET_AI_DID_NOT_OPEN = "SET_AI_DID_NOT"
export const CLEAR_AI_OPENED_AT = "CLEAR_AI_OPENED_AT"
export const CLEAR_CHECK_VALVE = "CLEAR_CHECK_VALVE"
export const CLEAR_AI_OPENED_FULLY = "CLEAR_AI_OPENED_AT"
export const CLEAR_CHECK_FAILED = "CLEAR_CHECK_FAILED"
export const CLEAR_AI_DID_NOT_OPEN = "CLEAR_AI_DID_NOT"

export const SET_FINAL_AI_OPENED_AT = "SET_FINAL_AI_OPENED_AT"
export const SET_FINAL_CHECK_VALVE = "SET_FINAL_CHECK_VALVE"
export const SET_FINAL_AI_OPENED_FULLY = "SET_FINAL_AI_OPENED_FULLY"
export const SET_FINAL_CHECK_FAILED = "SET_FINAL_CHECK_FAILED"
export const SET_FINAL_AI_DID_NOT_OPEN = "SET_FINAL_AI_DID_NOT_OPEN"
export const CLEAR_FINAL_AI_OPENED_AT = "CLEAR_FINAL_AI_OPENED_AT"
export const CLEAR_FINAL_CHECK_VALVE = "CLEAR_FINAL_CHECK_VALVE"
export const CLEAR_FINAL_AI_OPENED_FULLY = "CLEAR_FINAL_AI_OPENED_FULLY"
export const CLEAR_FINAL_CHECK_FAILED = "CLEAR_FINAL_CHECK_FAILED"
export const CLEAR_FINAL_AI_DID_NOT_OPEN = "CLEAR_FINAL_AI_DID_NOT_OPEN"


const clearOpenedAt = () =>  ({ type: CLEAR_AI_OPENED_AT })
const clearCheckValve = () =>  ({ type: CLEAR_CHECK_VALVE })
const clearDidNotOpen = () =>  ({ type: CLEAR_AI_DID_NOT_OPEN })
const clearOpenedFully = () =>  ({ type: CLEAR_AI_OPENED_FULLY })
const clearCheckFailed = () =>  ({ type: CLEAR_CHECK_FAILED })
const clearFinalOpenedAt = () =>  ({ type: CLEAR_FINAL_AI_OPENED_AT })
const clearFinalCheckValve = () =>  ({ type: CLEAR_FINAL_CHECK_VALVE })
const clearFinalDidNotOpen = () =>  ({ type: CLEAR_FINAL_AI_DID_NOT_OPEN })
const clearFinalOpenedFully = () =>  ({ type: CLEAR_FINAL_AI_OPENED_FULLY })
const clearFinalCheckFailed = () =>  ({ type: CLEAR_FINAL_CHECK_FAILED })


const setOpenedAt = (val) =>  ({ type: SET_AI_OPENED_AT, val })
const setCheckValve = (val) =>  ({ type: SET_CHECK_VALVE, val })
const setDidNotOpen = (truefalse) =>  ({ type: SET_AI_DID_NOT_OPEN, truefalse})
const setOpenedFully = (truefalse) =>  ({ type: SET_AI_OPENED_FULLY, truefalse })
const setCheckFailed = (truefalse) =>  ({ type: SET_CHECK_FAILED, truefalse })
const setFinalOpenedAt = (val) =>  ({ type: SET_FINAL_AI_OPENED_AT, val })
const setFinalCheckValve = (val) =>  ({ type: SET_FINAL_CHECK_VALVE, val })
const setFinalDidNotOpen = (truefalse) => ({ type: SET_FINAL_AI_DID_NOT_OPEN, truefalse })
const setFinalOpenedFully = (truefalse) =>  ({ type: SET_FINAL_AI_OPENED_FULLY, truefalse  })
const setFinalCheckFailed = (truefalse) => ({ type: SET_FINAL_CHECK_FAILED, truefalse})

export const clearXVBA = () => dispatch => {
    return new Promise((resolve, reject) => {
        let p1 = dispatch(clearOpenedAt())
        let p2 = dispatch(clearCheckValve())
        let p3 = dispatch(clearDidNotOpen())
        let p4 = dispatch(clearOpenedFully())
        let p5 = dispatch(clearCheckFailed())
        let p6 = dispatch(clearFinalOpenedAt())
        let p7 = dispatch(clearFinalCheckValve())
        let p8 = dispatch(clearFinalDidNotOpen())
        let p9 = dispatch(clearFinalOpenedFully())
        let p10 = dispatch(clearFinalCheckFailed())
        Promise.all([p1, p2, p3, p4, p5, p6, p7, p8, p9, p10]).then((data, err) => {
            resolve()
        })
    })
}

export const reduceOpenedAt = (val) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setOpenedAt(val))
        resolve()
    })
}
export const reduceCheckValve = (val) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setCheckValve(val))
        resolve()
    })
}
export const reduceDidNotOpen = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setDidNotOpen(truefalse))
        resolve()
    })
}
export const reduceOpenedFully = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setOpenedFully(truefalse))
        resolve()
    })
}
export const reduceCheckFailed = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setCheckFailed(truefalse))
        resolve()
    })
}


export const reduceFinalOpenedAt = (val) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setFinalOpenedAt(val))
        resolve()
    })
}
export const reduceFinalCheckValve = (val) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setFinalCheckValve(val))
        resolve()
    })
}
export const reduceFinalDidNotOpen = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setFinalDidNotOpen(truefalse))
        resolve()
    })
}
export const reduceFinalOpenedFully = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setFinalOpenedFully(truefalse))
        resolve()
    })
}
export const reduceFinalCheckFailed = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setFinalCheckFailed(truefalse))
        resolve()
    })
}