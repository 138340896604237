import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import Container from 'react-bootstrap/Container'
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Assembly from "./Assembly"
import Initial from "./initial/Initial"
import Final from "./final/Final"
import {
    requestReport
} from "../../actions/getter"


export default function MyBody() {
    const [key, setKey] = useState('device');
    const type = useSelector((state) => state.assembly_type);
    const size = useSelector((state) => state.assembly_size);
    const testType = useSelector((state) => state.test_type);
    const reportID = useSelector((state) => state.reportID)
    const [showForms, setShowForms] = useState(false) 
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        setShowForms(type != null && size != null && testType != null)
    }, [type, size, testType])

    useEffect(() => {
        if (reportID != null) {
            setLoading(true)
            dispatch(requestReport(reportID)).then((date, err) => {
                setLoading(false)
            })
        }
    }, [reportID])

    return(

        <Container fluid>
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="device" title="Device">
                    <Assembly />
                </Tab>

                <Tab eventKey="initial" title="Initial">
                    {
                        showForms ?
                           <> {
                                loading ?
                                    <Spinner />
                                    : 
                                    <Initial />
                            }</>
                            
                        : 
                        <> Please make sure to select an assembly type, size and test type</>
                   }   
                </Tab>
                <Tab eventKey="final" title="Final" >
                    {
                        showForms ?
                            <>
                                {
                                    loading ?
                                        <Spinner />
                                    : 
                                        <Final />
                                }
                            </>
                      
                        :
                            <> Please make sure to select an assembly type, size and test type</>
                     }  
                    
                </Tab>
            </Tabs>
        </Container>
    )
}