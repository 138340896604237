import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import LineItems from "./LineItems"
import ServiceAddress from "./ServiceAddress"
import BillingAddress from "./BillingAddress"
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { requestInvoiceDetails, requestInvoiceID } from "../../actions/invoiceApp"
import InvoiceOptions from "./InvoiceOptions"

 
export default function Invoice(){
	const stopID = useSelector((state) => state.stopID)
	const [loading, setLoading] = useState(false)
	const dispatch = useDispatch() 
	useEffect(()=>{
		if(stopID != null){
			setLoading(true)
			dispatch( requestInvoiceID(stopID) ).then((data, err) =>{
				setLoading(false)
			})
		}
	
	}, [])

	return(
		<> {
			loading ? 
				<Spinner />
			: 

			<Container >
				<Row>
					<Col>
						<InvoiceOptions />
					</Col>
				</Row>
				<hr/>
				<Row xs = {1} sm md lg = {1}>
					<Col>
						<ServiceAddress />
					</Col>
					
					<Col>	
						<BillingAddress />
					</Col>
				</Row>
				<hr/>
				<Row>
					<Col>
						<LineItems />
					</Col>
				</Row>
			</Container>

		} </>
		
	)
}