import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef, useCallback } from "react";
import {
    reduceFinalPass, reduceFinalFail
} from "../../../actions/finalResults"

export default function PassFail() {
    const pass = useSelector((state) => state.final_pass)
    const fail = useSelector((state) => state.final_fail)
    const [na, setNA] = useState(false)
    const dispatch = useDispatch()


    return (
        <Container>
            <Row>
                <Col>
                    <strong> System </strong>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Check
                        type="radio"
                        label="PASSED"
                        checked={pass}
                        onChange={
                            (event) => {
                                dispatch(reduceFinalPass(event.target.checked))
                                dispatch(reduceFinalFail(!event.target.checked))
                                setNA(false)
                            }
                        }
                    />
                    <Form.Check
                        type = "radio"
                        label="FAILED"
                        checked = {fail}
                        onChange={
                            (event) => {
                                dispatch(reduceFinalFail(event.target.checked))
                                dispatch(reduceFinalPass(!event.target.checked))
                                setNA(false)
                            }
                        }
                    />
                    <Form.Check
                        type="radio"
                        label="NA"
                        checked={na}
                        onChange={
                            (event) => {
                                dispatch(reduceFinalFail(false))
                                dispatch(reduceFinalPass(false))
                                setNA(true)
                            }
                        }
                    />
                </Col>
            </Row>
        </Container>
    )
}