import StopCard from "./StopCard"
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Badge from 'react-bootstrap/Badge'
import Accordion from 'react-bootstrap/Accordion'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { requestStoplist } from "../../actions/stopsApp"
import TestReport from "../reports/app.js"
import { reduceTimeWindows, reduceServiceTimes,
	clearDM, clearTW, clearOrd, clearIsRouted, requestStopServiceTime} from "../../actions/map"
import StartEnd from "../map/start_end"
import MapApp from "../map/app.js"

import {
    getTesterAddressList
} from "../../actions/settings"

export default function StopsApp() {
	const list = useSelector((state) => state.stoplist)
	const order = useSelector( (state) => state.stop_order)
	const [hasStops, setHasStops] = useState(false)
	const [stopsLoading, setStopsLoading] = useState(false)
	const mydate = useSelector((state) => state.dispatchDate)
	const routed = useSelector((state) =>state.routed)
	const dispatch = useDispatch();
	const [orderedlist, setOrderedList] = useState([])  

	function compare(val1, val2){
		return true
	}

	async function getTimes() {
		//console.log("Getting times: ",list)
		const temparr = await Promise.all([
			0, 
            list.map(item => requestStopServiceTime(item.stopID))
        ]);
        //console.log("This is the array: ", temparr)
        dispatch( reduceServiceTimes( temparr) ).then((data, err) =>{
        }) 
	}

	useEffect(()=>{
		if(list.length > 0 && mydate != null){
			getTimes()
		}
	}, [list])

	//creating time windows from a new stoplist 
	useEffect( () =>{	
		if(list.length > 0 && mydate != null){
			let tempWindows = []
			tempWindows.push({ start: `${mydate}T07:30:00`, end: `${mydate}T07:30:00` })

			for(let i = 0; i < list.length; i++){
				let tempWindow  = { start: `${mydate}T${list[i].startTime}:00`, end: `${mydate}T${list[i].endTime}:00`}
				tempWindows.push(tempWindow)				
			 }

			 dispatch( reduceTimeWindows (tempWindows )).then((data, err) => {
			 	//console.log("reduceed the time windows")
			 })
		}
	}, [list])

	useEffect(()=>{
        dispatch( getTesterAddressList() ).then((data, err) =>{
        })
     }, [])

	
 	useEffect(() => {
        if (list.length > 0) {
            setHasStops(true);
            setStopsLoading(true)
            //console.log("new order ", order, list)
        	let sortedList = []
            if (order.length > 0) {
            	
               	for(let i = 1; i < order.length; i++){
               		//console.log( "Pushing this one in: ", order[i])
               		sortedList.push( list[ order[i] - 1 ] ) 
               	}
                setOrderedList(sortedList);

            } else {
                setOrderedList(list);
            }
        } else {
            setHasStops(false);
        }
    }, [order, list]);


	useEffect(()=>{
		//console.log("ordered list: ", orderedlist )
		setStopsLoading(false)
	}, [orderedlist])

	useEffect(() => {
		//request date stops
		if (mydate != null) {
			setStopsLoading(true)
			let p1 = dispatch( clearDM() )
			let p2 = dispatch( clearTW() )
			let p3 = dispatch( clearOrd() ) 
			let p4 = dispatch( clearIsRouted() )
			Promise.all([p1, p2, p3, p4]).then((data, err) =>{
				dispatch(requestStoplist(mydate)).then((data, err) => {
					setStopsLoading(false)
				})
			})
			
		}
	}, [mydate])

	const renderlist = orderedlist.map((stop) => {
		return (
			<StopCard stop={ stop } />
		)
	})
	return (
		<Container  fluid className="main-body">
			<Row>
				<Col xs ={8} sm md lg = {8}>
					<StartEnd />
				</Col>
				<Col>
					<MapApp />
				</Col>
			</Row>
			<hr/>
			<Row> 
			{hasStops ? (
                stopsLoading ? (

                    <Spinner animation="border" />

                ) : (
                    <Col>
                        <TestReport />
                        <Accordion>{renderlist}</Accordion>
                    </Col>
                )
            ) : (
                <p>No Stops To Show</p>
            )}
            </Row>
		</Container>
	)
}