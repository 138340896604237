import {
  SET_OPTIONS_LIST, CLEAR_OPTIONS_LIST
} from "../../actions/invoiceApp";

const _nullList = []; 
             
export default (state = _nullList, { type, list }) => {
  Object.freeze(state);
  switch (type) {
    case  SET_OPTIONS_LIST:
      return list;
    case CLEAR_OPTIONS_LIST: 
      return _nullList
    default:
      return state;
  }
};