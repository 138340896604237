import * as server from "../util/updateServer";

export const updateSubmitter = (id) => {
    return new Promise(async (resolve, reject) => {
        const response = await server.updateSubmitter(id)
        const data = await response.json()
        if (response.ok) {
            resolve(data)
        }
    })
}
export const updateGaugeDetails = (id) => {
    return new Promise(async (resolve, reject) => {
        const response = await server.updateGaugeDetails(id)
        const data = await response.json()
        if (response.ok) {
            resolve(data)
        }
    })
}

export const updateAssembly = (id, obj) => {
    return new Promise(async (resolve, reject) => {
        const response = await server.updateAssembly(id, obj)
        const data = await response.json()
        if (response.ok) {
            resolve(data)
        }
    })
}
export const updateApproved = (id, obj) => {
    return new Promise(async (resolve, reject) => {
        const response = await server.updateApproved(id, obj)
        const data = await response.json()
        if (response.ok) {
            resolve(data)
        }
    })
}
export const updateAirgap = (id, obj) => {
    return new Promise(async (resolve, reject) => {
        const response = await server.updateAG(id, obj)
        const data = await response.json()
        if (response.ok) {
            resolve(data)
        }
    })
}

export const updateDoubleCheck = (id, obj) => {
    return new Promise(async (resolve, reject) => {
        const response = await server.updateDoubleCheck(id, obj)
        const data = await response.json()
        if (response.ok) {
            resolve(data)
        }
    })
}

export const updateSystem = (id, obj) => {
    return new Promise(async (resolve, reject) => {
        const response = await server.updateSystem(id, obj)
        const data = await response.json()
        if (response.ok) {
            resolve(data)
        }
    })
}

export const updateReliefValve = (id, obj) => {
    return new Promise(async (resolve, reject) => {
        const response = await server.updateReliefValve(id, obj)
        const data = await response.json()
        if (response.ok) {
            resolve(data)
        }
    })
}
export const updateXVBA = (id, obj) => {
    return new Promise(async (resolve, reject) => {
        const response = await server.updateXVBA(id, obj)
        const data = await response.json()
        if (response.ok) {
            resolve(data)
        }
    })
}

export const updateParts = (id, obj) => {
    return new Promise(async (resolve, reject) => {
        const response = await server.updateParts(id, obj)
        const data = await response.json()
        if (response.ok) {
            resolve(data)
        }
    })
}
export const updateRemarks = (id, obj) => {
    return new Promise(async (resolve, reject) => {
        const response = await server.updateRemarks(id, obj)
        const data = await response.json()
        if (response.ok) {
            resolve(data)
        }
    })
}

export const updateRemoved = (id, obj) => {
    return new Promise(async (resolve, reject) => {
        const response = await server.updateRemoved(id, obj)
        const data = await response.json()
        if (response.ok) {
            resolve(data)
        }
    })
}

export const updateServiceStatus = (id) => {
    return new Promise(async (resolve, reject) => {
        const response = await server.updateServiceStatus(id)
        const data = await response.json()
        if (response.ok) {
            resolve(data)
        }
    })
}