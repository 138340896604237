import {
    SET_USC, CLEAR_USC
} from "../../actions/approvedApp";

const _null = null;

export default (state = _null, { type, truefalse }) => {
    Object.freeze(state);
    switch (type) {
        case SET_USC:
            return truefalse;
        case CLEAR_USC:
            return _null
        default:
            return state;
    }
};