
export const SET_PIPESIZE = "SET_PIPESIZE"
export const SET_PHYSICAL_SEPARATION = "SET_PHYSICAL_SEPARATION"
export const SET_FINAL_PIPESIZE = "SET_FINAL_PIPESIZE"
export const SET_FINAL_PHYSICAL_SEPARATION = "SET_FINAL_PHYSICAL_SEPARATION"
export const CLEAR_PIPESIZE = "CLEAR_PIPESIZE"
export const CLEAR_PHYSICAL_SEPARATION = "CLEAR_PHYSICAL_SEPARATION"
export const CLEAR_FINAL_PIPESIZE = "CLEAR_FINAL_PIPESIZE"
export const CLEAR_FINAL_PHYSICAL_SEPARATION = "CLEAR_FINAL_PHYSICAL_SEPARATION"

const clearPipesize = () =>  ({ type: CLEAR_PIPESIZE })
const clearPhysicalSeparation = () =>  ({ type: CLEAR_PHYSICAL_SEPARATION })
const clearFinalPipesize = () =>  ({ type: CLEAR_FINAL_PIPESIZE })
const clearFinalPhysicalSeparation = () =>  ({ type: CLEAR_FINAL_PHYSICAL_SEPARATION })

const setPipesize = (val) =>  ({ type: SET_PIPESIZE, val })
const setPhysicalSeparation = (val) =>  ({ type: SET_PHYSICAL_SEPARATION, val })
const setFinalPipesize = (val) =>  ({ type: SET_FINAL_PIPESIZE, val })
const setFinalPhysicalSeparation = (val) => ({ type: SET_FINAL_PHYSICAL_SEPARATION , val })

export const clearAG = () => dispatch => {
    return new Promise((resolve, reject) => {
        let p1 = dispatch(clearPipesize())
        let p2 = dispatch(clearPhysicalSeparation())
        let p3 = dispatch(clearFinalPipesize())
        let p4 = dispatch(clearFinalPhysicalSeparation())
        Promise.all([p1, p2, p3, p4]).then((data, err) => {
            resolve()
        })
    })
}

export const reducePipesize = (val) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setPipesize(val))
        resolve()
    })
}
export const reducePhysicalSeparation = (val) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setPhysicalSeparation(val))
        resolve()
    })
}
export const reduceFinalPipesize = (val) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setFinalPipesize(val))
        resolve()
    })
}
export const reduceFinalPhysicalSeparation= (val) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setFinalPhysicalSeparation(val))
        resolve()
    })
}