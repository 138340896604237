import {
    SET_ASSEMBLY_SIZE, CLEAR_ASSEMBLY_SIZE
} from "../../actions/assemblyApp";

const _null = null;

export default (state = _null, { type, size }) => {
    Object.freeze(state);
    switch (type) {
        case SET_ASSEMBLY_SIZE:
            return size;
        case CLEAR_ASSEMBLY_SIZE:
            return _null
        default:
            return state;
    }
};