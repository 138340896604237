import {
    SET_NEW_SERIAL, CLEAR_NEW_SERIAL
} from "../../actions/removed";

const _null = null;

export default (state = _null, { type, val }) => {
    Object.freeze(state);
    switch (type) {
        case SET_NEW_SERIAL:
            return val;
        case CLEAR_NEW_SERIAL:
            return _null
        default:
            return state;
    }
};