import {
    SET_ASSEMBLY_ID, CLEAR_ASSEMBLY_ID
} from "../../actions/assemblyApp";

const _nullID = null;

export default (state = _nullID, { type, id }) => {
    Object.freeze(state);
    switch (type) {
        case SET_ASSEMBLY_ID:
            return id;
        case CLEAR_ASSEMBLY_ID:
            return _nullID
        default:
            return state;
    }
};