import {
  SET_TESTER_ADDRESS_LIST, CLEAR_TESTER_ADDRESS_LIST
} from "../../actions/settings";

const _null = []
                 
export default (state = _null, { type, list}) => {
  Object.freeze(state);
  switch (type) {
    case SET_TESTER_ADDRESS_LIST:
      return list;
    case '' : 
      return _null; 
    default:
      return state;
  }
};