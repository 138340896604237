import {
    SET_ASSEMBLY_MAKE, CLEAR_ASSEMBLY_MAKE
} from "../../actions/assemblyApp";

const _null = null;

export default (state = _null, { type, make }) => {
    Object.freeze(state);
    switch (type) {
        case SET_ASSEMBLY_MAKE:
            return make;
        case CLEAR_ASSEMBLY_MAKE:
            return _null
        default:
            return state;
    }
};