import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import ReadMore from "./Content"

import {
	showNotTested, hideNotTested, updateStopState, clearStoplist, requestStoplist, updateServiceState, requestStopServices
} from "../../actions/stopsApp"

export default function NotTested(props){
	const [showme, setShowMe] = useState(true)
	const [noTime, setNoTime] = useState(false);
	const [unableToLocate, setUnableToLocate] = useState(false)
	const [required, setRequired] = useState(true)
	const [reason, setReason] = useState('')
	const [applyToAll, setApplyToAll] = useState(false)
	const stopID = useSelector( (state) => state.stopID)
	const mydate = useSelector( (state) => state.dispatchDate)
	const [changeLoading, setChangeLoading] = useState(false)
	const dispatch = useDispatch();  

	useEffect(() =>{
		setRequired( !noTime || !unableToLocate)
	}, [noTime]) 

	useEffect(()=>{
		if( noTime ){
			let newReason = `Ran out of time; ${reason}`
			setReason(newReason)
		} else{
			let newReason = reason.replace("Ran out of time;", '')
			setReason(newReason)
		}

	
	}, [noTime])

	useEffect(()=>{
		if( unableToLocate){
			let newReason = `Unable to locate; ${reason}`
			setReason(newReason)
		}else{
			let newReason = reason.replace("Unable to locate;", '')
			setReason(newReason)
		}


	}, [unableToLocate])


	const send = (event)=>{
		event.preventDefault() 
		updateServiceState( false, reason, props.serviceID, applyToAll).then((data, err) =>{
			//request new list of services for stop
			dispatch(requestStopServices(stopID)).then((data, err) => {
				props.closeMeFalse()

			})
		})
	}

	return(
		<Modal show={true} centered >
			<Modal.Header closeButton onHide = { () => {
				console.log(props)
				props.closeMeTrue()

			}  }>
			</Modal.Header>
			<Form onSubmit = {send}>
			<Modal.Body>
				
					<Form.Label> Reason </Form.Label> <br/>
					<Form.Text> 
					This will help us determine 
					if we need to reschedule or reach out back to the client </Form.Text>
					<Form.Control
						value = {reason}
						onChange = {
							(event) =>{
								setReason(event.target.value)
							}
						}

						 required = { required }  />
					<Form.Check  checked = {noTime} 
					onChange = { (event) => setNoTime(event.target.checked)}  label = "Ran out of time"/>
					<Form.Check  checked = {unableToLocate} 
					onChange = { (event) => setUnableToLocate(event.target.checked)}  label = "Unable to locate"/>
				
			</Modal.Body>
			<Modal.Footer>
				<Row>
					<Col>
						<Form.Check  
							label = "Apply to all"
							checked = {applyToAll}
							onChange = { (event) =>{
								setApplyToAll(event.target.checked)
							}}
						/>
					</Col>
					<Col>
						{	
							changeLoading ?
								<Spinner />
							: 
								<Button type ="submit"> Not Tested</Button>
						}
					</Col>
				</Row>
				
			</Modal.Footer>
			</Form>
		</Modal>
	)

}