import {
    SET_SEAT, CLEAR_SEAT
} from "../../actions/parts";

const _null = null;

export default (state = _null, { type, truefalse }) => {
    Object.freeze(state);
    switch (type) {
        case SET_SEAT:
            return truefalse;
        case CLEAR_SEAT:
            return _null
        default:
            return state;
    }
};