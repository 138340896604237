import DCForm from "./DCForm"
import RPForm from "./RPForm"
import XVBAForm from "./XVBAForm"
import AGForm from "./AGForm"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";

export default function Results(){
	const assemblyType = useSelector( (state) => state.assembly_type )
	const [formToShow, setFormToShow] = useState()

	useEffect(()=>{
		switch(assemblyType){
			case 'DC': 
			case 'DCDA': 
			case 'DCDAII': 
				setFormToShow(<DCForm />)
				break; 
			case 'RP': 
			case 'RPDA': 
			case 'RPDAII':
				setFormToShow(<RPForm />)
				break; 
			case 'PVB': 
			case 'SVB': 
			case 'AVB': 
				setFormToShow(<XVBAForm />)
				break; 
			case 'AG': 
				setFormToShow(<AGForm />)
				break; 
			default: 
				setFormToShow(<> Please Select a device type </>)

		}

	}, [assemblyType])

	return(
		<Container fluid>
			<Row>
				<Col>
					{formToShow}
				</Col>
			</Row>
			
		</Container>
	)
}