import * as server from "../util/alternativeServer";

export const ASSEMBLY_LIST = "ASSEMBLY_LIST"
export const TEST_REPORT = "TEST_REPORT"
export const CONFINED_LIST = "CONFINED_LIST"
export const CONFINED_ENTRY = "CONFINED_ENTRY"

const receiveAssemblyList = (list) =>({
	type: ASSEMBLY_LIST, 
	list
}) 


const receiveTestReport = (report) => ({
	type: TEST_REPORT, 
	report
})

const receiveConfinedEntryList = (list) => ({
	type: CONFINED_LIST, 
	list
})

const receiveConfinedEntry = (entry) => ({
	type: CONFINED_ENTRY, 
	entry

})

export const getAddressDetails = (addressID) => {
	return new Promise( async (resolve, reject)=>{
		const response = await server.requestAddressDetails(addressID); 
		const data = await response.json(); 
		if(response.ok){
			resolve(JSON.parse(data).address)
		}
	})
}

export const getCustomerDetails = (customerID) => {
	return new Promise( async (resolve, reject)=>{
		const response = await server.requestCustomerDetails(customerID); 
		const data = await response.json(); 
		if(response.ok){
			resolve(JSON.parse(data).customer)
		}
	})
}

export const getServices = (stopID) => dispatch =>{
	return new Promise( async(resolve, reject) =>{
		const response = await server.requestServices(stopID); 
		const data = await response.json(); 
		if(response.ok){
				dispatch( receiveAssemblyList(JSON.parse(data).list) ); 
				resolve()
		}
	})
}


export const getInvoiceStatus = (invoiceID) =>{
	return new Promise( async(resolve, reject) =>{
		const response = await server.requestInvoiceStatus(invoiceID); 
		const data = await response.json(); 
		if(response.ok){
				resolve(JSON.parse(data).status)
		}
	})
}

export const getTestReport = (reportID) => dispatch =>{
	return new Promise( async(resolve, reject) =>{
		const response = await server.requestReport(reportID); 
		const data = await response.json(); 
		if(response.ok){
				dispatch( receiveTestReport(JSON.parse(data).report) ); 
				resolve()
		}
	})
}

export const requestConfinedEntries = (addressID) => dispatch => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.requestConfinedEntries(addressID); 
		const data = await response.json(); 
		if(response.ok){
				dispatch( receiveConfinedEntryList(JSON.parse(data).list) ); 
				resolve()
		}
	})

}
export const deleteEntry = ( entryID, addressID) => dispatch =>{
	return new Promise ( async (resolve, reject) =>{
		const response = await server.deleteConfinedEntry( entryID, addressID); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveConfinedEntryList( JSON.parse(data).list)); 
			resolve(); 
		}
	})
}

export const requestEntry = ( entryID ) => dispatch =>{
	return new Promise ( async (resolve, reject) =>{
		const response = await server.requestConfinedEntry( entryID); 
		const data = await response.json(); 
		if(response.ok){
		
			dispatch( receiveConfinedEntry( JSON.parse(data).entry ) ); 
			resolve(); 
		}
	})
}

export const updateConfinedDetails = ( entryID, obj) => dispatch => {
	return new Promise (async(resolve, reject) =>{
		const response = await server.updateConfinedDetails({entryID: entryID, obj : obj}); 
		const data = await response.json(); 
		if(response.ok){

			dispatch( receiveConfinedEntry( JSON.parse(data).entry) ); 
			resolve(); 
		}

	})
}
export const updateConfinedReadings = ( entryID, obj) => dispatch => {
	return new Promise (async(resolve, reject) =>{
		const response = await server.updateConfinedReadings({entryID: entryID, obj : obj}); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveConfinedEntry( JSON.parse(data).entry) ); 
			resolve(); 
		}

	})
}
export const updateConfinedHazards = ( entryID, obj) => dispatch => {
	return new Promise (async(resolve, reject) =>{
		const response = await server.updateConfinedHazards({entryID: entryID, obj : obj}); 
		const data = await response.json(); 
		if(response.ok){
			dispatch( receiveConfinedEntry( JSON.parse(data).entry) ); 
			resolve(); 
		}

	})
}

export const createConfinedEntry = (addressID) => dispatch => {
	return new Promise( async(resolve, reject) =>{
		const response = await server.createConfinedEntry(addressID); 
		const data = await response.json(); 
		if(response.ok){
				dispatch( receiveConfinedEntryList(JSON.parse(data).list) ); 
				resolve()
		}
	})
} 
export const createAssembly= (addressID, stopID) => { 
	return new Promise( async(resolve, reject) =>{
		const response = await server.createAssembly(addressID, stopID); 
		const data = await response.json(); 
		if(response.ok){
			
				resolve()
		}
	})
}