import FinalDCForm from "./FinalDCForm"
import FinalRPForm from "./FinalRPForm"
import FinalXVBAForm from "./FinalXVBAForm" 
import FinalAGForm from "./FinalAGForm" 
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import {useSelector, useDispatch} from "react-redux";
import { useState, useEffect, useRef, useCallback} from "react";

export default function FinalResults(){

	const assemblyType = useSelector( (state) => state.assembly_type )
	const [formToShow, setFormToShow] = useState()

	useEffect(()=>{
		switch(assemblyType){
			case 'DC': 
			case 'DCDA': 
			case 'DCDAII': 
				setFormToShow(<FinalDCForm />)
				break; 
			case 'RP': 
			case 'RPDA': 
			case 'RPDAII':
				setFormToShow(<FinalRPForm />)
				break; 
			case 'PVB': 
			case 'SVB': 
			case 'AVB': 
				setFormToShow(<FinalXVBAForm />)
				break; 
			case 'AG': 
				setFormToShow(<> No repairs here...</>)
				break; 
			default: 
				setFormToShow(<> Please Select a device type </>)

		}

	}, [assemblyType])
	return(
		<Container fluid>
			<Row>
				<Col>
					{formToShow}
				</Col>
			</Row>
			
		</Container>
	)
}