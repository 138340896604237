import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Badge from 'react-bootstrap/Badge'
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	reduceCheck1,
	reduceCheck2,
	reduceCheck1Tight,
	reduceCheck1Leaked,
	reduceCheck2Tight,
	reduceCheck2Leaked,
	reduceTypeII
} from "../../../actions/dc"

import {
	reduceTarget
} from "../../../actions/NumberPad"

function Editable() {
	const check1 = useSelector((state) => state.check1)
	const check1_tight = useSelector((state) => state.check1_tight)
	const check2_tight = useSelector((state) => state.check2_tight)
	const check1_leaked = useSelector((state) => state.check1_leaked)
	const check2_leaked = useSelector((state) => state.check2_leaked)
	const typeII = useSelector((state) => state.dc_typeII)
	const check2 = useSelector((state) => state.check2)
	const dispatch = useDispatch()


	useEffect(() => {
		if (check1 != null) {
			if (check1 >= 1.0) {
				dispatch(reduceCheck1Tight(true))
				dispatch( reduceCheck1Leaked(false))
			} else {
				dispatch(reduceCheck1Tight(false))
				dispatch(reduceCheck1Leaked(true))
			}
		}
		if (check2 != null) {
			if (check2 >= 1.0) {
				dispatch(reduceCheck2Tight(true))
				dispatch(reduceCheck2Leaked(false))
			} else {
				dispatch(reduceCheck2Tight(false))
				dispatch(reduceCheck2Leaked(true))
			}
		}
	}, [check1, check2])

	return (
		<>
			<Row xs={1} sm={1} md={2} lg={2}>
				<Col>
					{ 
						typeII ?
							<>
							</>
						:

							<InputGroup>
								<InputGroup.Text>
									#1
								</InputGroup.Text>
								<Form.Control
									id="check1_psid"
									value={ check1}
									readOnly
									onFocus={
										(event) => {
											dispatch(reduceTarget(
												{
													name: "CHECK1",
													slice: reduceCheck1,
													target: event.target, 
													current: check1
												}
											))
										}
									}
								/>
								{
									check1_tight ?
										<Badge bg="primary">Tight</Badge>
										:
										<> </>
								}
								{
									check1_leaked ?
										<Badge bg="secondary"> Leaked </Badge>
										:
										<> </>
								}
										
							</InputGroup>
					}
					

					<InputGroup>
						<InputGroup.Text>
							#2
						</InputGroup.Text>

						<Form.Control
							id="check2_psid"
							value={check2}
							readOnly
							onFocus={
								(event) => {
									dispatch(reduceTarget(
										{
											name: "CHECK2",
											slice: reduceCheck2,
											target: event.target, 
											current: check2
										}
									))
								}
							}
						/>
				
						{
							check2_tight ?
								<Badge bg="primary">Tight</Badge>
								:
								<> </>
						}
						{
							check2_leaked ?
								<Badge bg="secondary"> Leaked </Badge>
								:
								<> </>
						}


					
					</InputGroup>
				</Col>
			</Row>
		</>
		
	)

}

export default function DoubleCheck() {

	const dispatch = useDispatch() 
	const typeII = useSelector((state) => state.dc_typeII)
   return (
       <Container>
		   <Row>
			   <Col>
				   <strong> Double Check </strong>
			   </Col>
			   <Col>
				   <Form.Check
					   id="check1_typeII"
					   label="Type II"
					   checked={typeII}
					   onChange={
						   (event) => {
							   dispatch(reduceTypeII( event.target.checked))
						   }
					   }
				   />
			   </Col>
		   </Row>
		
		  <Editable />

		  
       </Container>
   )

}