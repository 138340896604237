


export const SET_INITIAL_PASS = "SET_INITIAL_PASS"
export const CLEAR_INITIAL_PASS = "SET_CLEAR_INITIAL_PASS"
export const SET_INITIAL_FAIL = "SET_INITIAL_FAIL"
export const CLEAR_INITIAL_FAIL = "SET_CLEAR_INITIAL_FAIL"
export const SET_RESTORED = "SET_RESTORED"
export const CLEAR_RESTORED = "CLEAR_RESTORED"

/*CLEAR UP*/
const clearFail = () => ({  type: CLEAR_INITIAL_FAIL })
const clearPass = () => ({ type: CLEAR_INITIAL_PASS })
const clearRestored = () => ({ type: CLEAR_RESTORED })
export const clearInitialResults = () => dispatch => {
    return new Promise((resolve, reject) => {
        let p1 = dispatch(clearFail())
        let p2 = dispatch(clearPass())
        let p3 = dispatch(clearRestored())
        Promise.all([p1, p2, p3]).then((data, err) => {
            resolve();
        })
    })
}

/*SETTERS*/
const setFail = (truefalse) => ({ type: SET_INITIAL_FAIL, truefalse })
const setPass = (truefalse) => ({ type: SET_INITIAL_PASS, truefalse })
const setRestored = (truefalse) => ({ type: SET_RESTORED, truefalse })

export const reducePass = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setPass(truefalse))
        resolve()
    })
}
export const reduceFail = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setFail(truefalse))
        resolve()
    })
}
export const reduceRestored = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setRestored(truefalse))
        resolve()
    })
}
