
export const SET_RP_OPENED_AT = "SET_RP_OPENED_AT"
export const SET_PRESSDROP = "SET_PRESSDROP"
export const SET_RP_DID_NOT_OPEN = "SET_RP_DID_NOT_OPEN"
export const SET_FINAL_RP_OPENED_AT = "SET_FINAL_RP_OPENED_AT"
export const SET_FINAL_PRESSDROP = "SET_FINAL_PRESSDROP"
export const SET_FINAL_RP_DID_NOT_OPEN = "SET_FINAL_RP_DID_NOT_OPEN"
export const SET_RP_TYPE_II = "SET_RP_TYPE_II"

export const CLEAR_RP_OPENED_AT = "CLEAR_RP_OPENED_AT"
export const CLEAR_PRESSDROP = "CLEAR_PRESSDROP"
export const CLEAR_RP_DID_NOT_OPEN = "CLEAR_RP_DID_NOT_OPEN"
export const CLEAR_FINAL_RP_OPENED_AT = "CLEAR_FINAL_RP_OPENED_AT"
export const CLEAR_FINAL_PRESSDROP = "CLEAR_FINAL_PRESSDROP"
export const CLEAR_FINAL_RP_DID_NOT_OPEN = "CLEAR_FINAL_RP_DID_NOT_OPEN"
export const CLEAR_RP_TYPE_II = "CLEAR_RP_TYPE_II"

const clearOpenedAt = () =>  ({ type: CLEAR_RP_OPENED_AT })
const clearPressdrop = () =>  ({ type: CLEAR_PRESSDROP })
const clearRPDidNotOpen = ()  => ({ type: CLEAR_RP_DID_NOT_OPEN })
const clearFinalOpenedAt = ()  => ({ type: CLEAR_FINAL_RP_OPENED_AT })
const clearFinalPressdrop = () => ({ type: CLEAR_FINAL_PRESSDROP })
const clearFinalRPDidNotOpen = () => ({ type: CLEAR_FINAL_RP_DID_NOT_OPEN })
const clearRPTypeII = () => ({ type: CLEAR_RP_TYPE_II})

const setOpenedAt = (val)  => ({ type: SET_RP_OPENED_AT,val })
const setPressdrop = (val)  => ({ type: SET_PRESSDROP, val })
const setRPDidNotOpen = (truefalse) => ({ type: SET_RP_DID_NOT_OPEN, truefalse })
const setRPTypeII = (truefalse) => ({ type: SET_RP_TYPE_II, truefalse })
const setFinalOpenedAt = (val) =>  ({ type: SET_FINAL_RP_OPENED_AT, val })
const setFinalPressdrop = (val) =>  ({ type: SET_FINAL_PRESSDROP, val })
const setFinalRPDidNotOpen = (truefalse) => ({ type: SET_FINAL_RP_DID_NOT_OPEN, truefalse })

export const clearRP = () => dispatch => {
    return new Promise((resolve, reject) => {
        let p1 = dispatch(clearOpenedAt())
        let p2 = dispatch(clearPressdrop())
        let p3 = dispatch(clearRPDidNotOpen())
        let p4 = dispatch(clearFinalOpenedAt())
        let p5 = dispatch(clearFinalPressdrop())
        let p6 = dispatch(clearFinalRPDidNotOpen())
        let p7 = dispatch(clearRPTypeII())
        Promise.all([p1, p2, p3, p4, p5, p6]).then((data, err) => {
            resolve()
        })
    })
}

export const reduceRpTypeII= (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setRPTypeII(truefalse))
        resolve()
    })
}
export const reduceOpenedAt = (val) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setOpenedAt(val))
        resolve()
    })
}
export const reducePressdrop = (val) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setPressdrop(val))
        resolve()
    })
}
export const reduceDidNotOpen = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setRPDidNotOpen(truefalse))
        resolve()
    })
}

export const reduceFinalOpenedAt = (val) => dispatch => {
    return new Promise((resolve, reject) => {
        console.log("Reducing: ", val)
        dispatch(setFinalOpenedAt(val))
        resolve()
    })
}
export const reduceFinalPressdrop = (val) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setFinalPressdrop(val))
        resolve()
    })
}
export const reduceFinalDidNotOpen = (truefalse) => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(setFinalRPDidNotOpen(truefalse))
        resolve()
    })
}
