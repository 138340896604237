import * as apiUtil from "../util/server";

export const RECEIVE_CURRENT_USER = "RECEIVE_CURRENT_USER"; 
export const UPDATE_SESSION = "UPDATE_SESSION"; 
export const LOGOUT_CURRENT_USER = "LOGOUT_CURRENT_USER" ;
export const FAILED_LOGIN = "FAILED_LOGIN"



const receiveUser = user =>({
	type: RECEIVE_CURRENT_USER, 
	user
})
const destroyUser = () => ({
	type: LOGOUT_CURRENT_USER
})

const failedAttempt = () => ({
	type: FAILED_LOGIN
})

export const  resetPassword = (newPassword) => async dispatch => {
		return new Promise( async(resolve, reject) =>{
			const response = await  apiUtil.resetActivatePassword(newPassword); 
			const data = await response.json(); 
			if(response.ok){
				resolve( dispatch( destroyUser() ));	
			}else{
				reject()
			}
		})
}

export const login = (credentials) => async dispatch  =>{
	return new Promise( async (resolve, reject)=>{
		console.log("Gotta log this one in: ", credentials); 
		const response = await apiUtil.login(credentials); 
		const data = await response.json(); 

		if(JSON.parse(data).success){
			let tempUser = { username: "Gema Hernandez", testlist: []} 
			dispatch(receiveUser(JSON.parse(data).session ) ); 
			resolve(true); 
		}else{
			console.log("Login failed..."); 
			dispatch( failedAttempt() ); 
			resolve(false)
		}	
	})
	
}

export const logout = () => async dispatch =>{
	console.log("loggin out..."); 

	const response = await apiUtil.logout(); 
	const data = await response.json(); 
	if(response.ok){
		return dispatch( destroyUser() ); 
	}else{
		console.log("Logout failed..."); 
	}
}

export const getTestList = (date) => async dispatch => {
	return new Promise( async(resolve, reject)=>{
		console.log("testlist for this date: ", date); 
		const response = await apiUtil.getTests(date); 
		const data = await response.json(); 

		if(JSON.parse(data).success){
			 dispatch(receiveUser(JSON.parse(data).session) ); 
			 resolve(true)
		}else{
			console.log("Login failed..."); 
			dispatch( destroyUser() ); 
			resolve(false); 
		}
	})
}
	