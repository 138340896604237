/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Badge from 'react-bootstrap/Badge'
import InputGroup from 'react-bootstrap/InputGroup'
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	reduceOpenedAt, reduceCheckValve,
	reduceDidNotOpen, reduceOpenedFully, reduceCheckFailed
} from "../../../actions/xvba"
import {
	reduceTarget
} from "../../../actions/NumberPad"

export default function XVB() {
	const ai_opened_at = useSelector((state) => state.ai_opened_at)
	const check_valve = useSelector((state) => state.check_valve)
	const ai_did_not_open = useSelector((state) => state.ai_did_not_open)
	const ai_opened_fully = useSelector((state) => state.ai_opened_fully)
	const check_valve_failed = useSelector((state) => state.check_valve_failed)
	const dispatch = useDispatch()

	useEffect(() => {
		if (check_valve != null) {
			if (Number(check_valve) >= 1.0) {
				dispatch( reduceCheckFailed(false))
			} else {
				dispatch(reduceCheckFailed(true))
			}
		}
	}, [check_valve])

    return (
		<Container>
			<Row>
				<Col>
					<strong> PVBA / SVBA </strong>
				</Col>
				
			</Row>
		
			<Row>
				<Col>
					<InputGroup size="sm">
						<InputGroup.Text> AI opened at</InputGroup.Text>
						<Form.Control
							value={ ai_opened_at}
							readOnly
							onFocus={
								(event) => {
									dispatch(reduceTarget(
										{
											name: "AIOpenedAt",
											slice: reduceOpenedAt,
											target: event.target
										}
									))
								}
							}

						/>
					</InputGroup>
				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Check
						label="Opened fully"
						value={ai_opened_fully}
						onChange={
							(event) => {
								dispatch(reduceOpenedFully(event.target.checked) )
							}
						}

					/>
				</Col>
				<Col>
					<Form.Check
						label="Did not open"
						value={ai_did_not_open}
						onChange={
							(event) => {
								dispatch(reduceDidNotOpen(event.target.checked))
							}
						}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<InputGroup size="sm">
						<InputGroup.Text>CV Pressdrop</InputGroup.Text>
						<Form.Control
							value={check_valve}
							readOnly
							onFocus={
								(event) => {
									dispatch(reduceTarget(
										{
											name: "Check Valve",
											slice: reduceCheckValve,
											target: event.target
										}
									))
								}
							}
						/>

						{
							check_valve_failed ?
								<Badge bg ="warning"> Failed</Badge>
								: 
								<>
								</>
						}
					</InputGroup>
				</Col>
			</Row>

		</Container>
    )
}