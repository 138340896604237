import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import InputGroup from 'react-bootstrap/InputGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect, useRef, useCallback} from "react";
import {updateSerial, updateLocation, updateMake, updateModel, 
		updateSize, updateHazard, updateType, 
		updateStatus, getAssembly, updateAssembly,
		reduceAssemblyType
	} from "../actions/AlternativeDetails"
import {useSelector, useDispatch} from "react-redux";


export default function Assembly(){	
	const assembly= useSelector( (state) => state.assembly)
	const assemblyID = useSelector( (state) => state.assembly_id )
	const [spinner, setSpinner] = useState(false); 
	const dispatch = useDispatch(); 
	const report = useSelector( (state) => state.report)
	const [modalSpinner, setModalSpinner] = useState(false)

	const [allowEdit, setAllowEdit] = useState(true) 

	/*value variables*/
	const [serial, setSerial] = useState(null); 
	const [location, setLocation] = useState(null); 
	const [size, setSize] = useState(null);
	const [make, setMake] = useState(null); 
	const [model, setModel] = useState(null); 
	const [hazard, setHazard] = useState(null); 
	const [type, setType]  = useState(null); 
	const [status, setStatus] = useState(null); 


	useEffect(()=> {
		if(assemblyID != null){
			//request assembly details
			setAllowEdit(false)
			dispatch( getAssembly(assemblyID) ).then((data, err) =>{
				setAllowEdit(true)
			})
		}
	}, [assemblyID])


	useEffect( () =>{
		if(assembly != null){
			setSerial(assembly.serial_number)
			setLocation(assembly.location)
			setSize(assembly.size)
			setMake(assembly.make)
			setModel(assembly.model)
			setHazard(assembly.hazard_type)
			setType(assembly.type)
			setStatus(assembly.state)	
		}
	}, [assembly])

	const update = () => {
		if(assemblyID != null){
			let obj = {}; 
			obj.id = assemblyID; 
			obj.type = type; 
			obj.size=	size;  	
			obj.make = make; 
			obj.model = model; 
			obj.serial =  serial;
			obj.hazard = hazard; 
			obj.location =  location; 
			console.log("Updating these values: ", obj)
			setAllowEdit(false)
			dispatch(updateAssembly(assemblyID, obj) ).then((data, err) =>{	
				setAllowEdit(true)
				console.log("Assembly updated!")
			})
		}
		
	}

	useEffect(()=>{
		if(size != null){
			update()
		}
		

	}, [size])

	useEffect(()=>{
		if(type != null){
			update()
		}
		
	}, [type])

	useEffect(()=>{
		dispatch( reduceAssemblyType(type) ).then((data, err) =>{
			console.log("Reduced!")
		})
	}, [type])


	return(
		<Container fluid>
			
					<Form>
						<hr/>
							<Row xs = {1}>
								<Col>

									<InputGroup >
										<InputGroup.Text>Serial Number</InputGroup.Text>
										<Form.Control 
												id="serial"
												value = {serial}
												onChange = { (event) => setSerial(event.target.value.toUpperCase()) }
												onBlur = { update }
											/>
									</InputGroup>
									
								</Col>
							
								</Row>
							<Row xs = {1}>
								<Col>
									<InputGroup>
										<InputGroup.Text> Location </InputGroup.Text>
										
											<Form.Control 
												type="text"
												id="location"
												value = {location}
												onChange = { (event) => setLocation(event.target.value) }
												onBlur = { update }
											/>
									</InputGroup>
								</Col>
								<Col>	
									<InputGroup>
										<InputGroup.Text>Make</InputGroup.Text>
										<Form.Control
											type="text"
											id="make"
											value = {make}
											onChange = { (event) => setMake(event.target.value) }
											onBlur = { update }
										/>
									</InputGroup>
								</Col>

								<Col>
									<InputGroup>
										<InputGroup.Text>Model</InputGroup.Text>
										<Form.Control
											type="text"
											id="model"
											value = {model}
											onChange = { (event) => setModel(event.target.value) }
											onBlur = {update}
										/>
									</InputGroup>
								</Col>
								<Col>
									<InputGroup>

										<InputGroup.Text>Size</InputGroup.Text>
										<Form.Select 
											id="size" 
											value = { size } 
											onChange= { (event) => setSize(event.target.value) } 
										>
											<option value= ""> Select a size </option>
											<option value="3/8">3/8"</option>
											<option value="1/2">1/2"</option>
											<option value="3/4">3/4"</option>
											<option value="1">1"</option>
											<option value= "1 1/4"> 1 1/4"</option>
											<option value="1.5">1 1/2"</option>
											<option value="2">2"</option>
											<option value="2.5">2 1/2"</option>
											<option value="3">3"</option>
											<option value="4">4"</option>
											<option value="6">6"</option>
											<option value="8">8"</option>
											<option value="10">10"</option>
											<option value="12">12"</option>
										</Form.Select>
										
									</InputGroup>
								</Col>
								<Col>
									<InputGroup>
										<InputGroup.Text> Type </InputGroup.Text> 
										
											<Form.Select

												type="text"
												id="type"
												value = {type}
												onChange = {
													(event) => 
													{
														setType(event.target.value)
													 }
												}	
											>
												<option value = {null}> Please choose one </option>
												<option value = "DC"> DC</option>
												<option value = "DCDA"> DCDA </option>
												<option value = "DCDAII"> DCDA-II </option>
												<option value = "RP"> RP </option>
												<option value = "RPDA"> RPDA </option>
												<option value = "RPDAII"> RPDA-II</option>
												<option value="PVB"> PVB </option>
												<option value="SVB"> SVB </option>
												<option value="AVB"> AVB </option>
												<option value = "AG"> AG </option>
											</Form.Select>
										
									</InputGroup>
								</Col>
								<Col>
									<InputGroup>
										<InputGroup.Text>Hazard</InputGroup.Text>
										<Form.Control
											type="text"
											id="hazard"
											value = {hazard}
											onChange = { (event) => setHazard(event.target.value) }
											onBlur = { update}
										/>
									</InputGroup>
								</Col>
							</Row>
						</Form>
			
					
			
		</Container>
	)
}