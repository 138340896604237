import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { requestServiceDetails, updateServiceDetails} from "../../actions/invoiceApp"

export default function ServiceAddress(){
	const addressID = useSelector((state) => state.addressID)
	const [initialAddress, setInitialAddress] = useState(null)
	const [name, setName] = useState(null)
	const [street, setStreet] = useState(null)
	const [city, setCity] = useState(null)
	const [state, setState] = useState(null)
	const [zipcode, setZipcode] = useState(null)

	const [loading, setLoading] = useState(null)
	const [updating, setUpdating] = useState(null)
	const [edit, setEdit] = useState(false)

	useEffect(()=>{
		if(initialAddress != null){
			setName(initialAddress.location_name)
			setStreet(initialAddress.street)
			setCity(initialAddress.city)
			setState(initialAddress.state)
			setZipcode(initialAddress.zipcode)
		}

	}, [initialAddress])

	useEffect(()=>{
		if(addressID != null){
			setLoading(true)	
			requestServiceDetails(addressID).then((data, err)=>{
				setInitialAddress(data)
				setLoading(false)
			})
		}
	}, [addressID])

	const save = () =>{
		let obj = {};
		obj.name = name;
		obj.street = street;
		obj.city = city; 
		obj.state = state;
		obj.zipcode = zipcode;
		updateServiceDetails(addressID, obj).then((data, err) =>{
			setLoading(true)
			requestServiceDetails(addressID).then((data, err) =>{
				setLoading(false) 
				setInitialAddress(data)
				setEdit(false)
			})
		}) 
	}

	return(

		<>{
			edit ? 
				<Container>				
					<Row>
						<Col xs = {8} sm md ={8} lg = {10}>
							<Form.Label> Service Address : </Form.Label>
							<InputGroup size= "sm">
								<InputGroup.Text>Name </InputGroup.Text>
								<Form.Control 
									value = {name}
									onChange= {
										(event) =>{
											setName(event.target.value)
										}
									}
								/>
							</InputGroup>
							<InputGroup size= "sm">
								<InputGroup.Text>Street </InputGroup.Text>
								<Form.Control
									value = {street}
									onChange= {
										(event) =>{
											setStreet(event.target.value)
										}
									}
								 />
							</InputGroup>
							<InputGroup size= "sm">
								<InputGroup.Text>City</InputGroup.Text>
								<Form.Control 
									value = {city}
									onChange= {
										(event) =>{
											setCity(event.target.value)
										}
									}
								/>
							</InputGroup >
							<InputGroup size= "sm">
								<InputGroup.Text>State</InputGroup.Text>
								<Form.Control 
									value = {state}
									onChange= {
										(event) =>{
											setState(event.target.value)
										}
									}
								/>
							</InputGroup>
							<InputGroup size= "sm">
								<InputGroup.Text>Zipcode</InputGroup.Text>
								<Form.Control 
									value = {zipcode}
									onChange= {
										(event) =>{
											setZipcode(event.target.value)
										}
									}
								/>
							</InputGroup>

						</Col>
						<Col>
							<Button 
								size= "sm"
								variant = "primary"
								onClick = {save}
								> Save </Button>
						</Col>
					</Row>
				</Container>
			: 
				<Container>
					<Row>
						<Col>
							Service Address: <br/> 
							<u>{name}</u> <br/>
							 {street}, {city}, <br/>{state}, {zipcode}
							
						</Col>
						{/*<Col>
							<Button 
								variant = "link"
								onClick = {() =>setEdit(true)}
								> Edit </Button>
						</Col>*/}
					</Row>
				</Container>

		}</>
		
	)
	
}