import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState, useEffect, useRef, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import Draggable from 'react-draggable';
import { pendingUpdatedValue } from "../actions/NumberPad"
import  { 
	updateSystemPSI, 
	updateDMR, 
	updateCheck1, updateCheck2,
	updateDoubleCheck, updateFinalCheck2,
	updateFinalCheck1, updateFDoubleCheck, 
	updateRvOpenedAt, updateRvPressdrop,
	updateFinalRvPressdrop, updateFinalRvOpenedAt, 
	updateSystem, 
	updateFinalCheckPressdrop, 
	updateFinalAirOpened, 
	updateAirOpened, 
	updateCheckPressdrop, 
	updatePipesize, 
	updatePhysicalSeparation, 
	updateFinalPipesize, 
	updateFinalPhysicalSeparation

} from "../actions/report"

export default function NumberPad(){
	const targetElement = useSelector((state) => state.numberPad)
	const dispatch = useDispatch()

	const grabValue = (event) => {
		if ("vibrate" in navigator) {
			// vibration API supported
  			navigator.vibrate(1000);
		}
		if(targetElement != null ){
			let myElement = document.querySelector(targetElement); 
			if(event.target.id == "CL"){
				myElement.value = ""
			}else if( event.target.id == "."){
				//nothing 	
				if ( myElement.value.includes(".") ){
					//nothing
				}else{
					if(targetElement != "#dmr"){
						let newString = String(myElement.value) + String(event.target.id); 
						myElement.value = newString.replace(/^0+/ , "");
					}else{
						let newString = String(myElement.value) + String(event.target.id);
						myElement.value = newString
					}
				}
			} else{
				if ( myElement.value.includes(".") ){
					//check length after the period 
					//get the index of the element where the period is stored 
					let periodIndex = myElement.value.indexOf(".");
					//compare to the length of string 
					if(myElement.value.length  - periodIndex <= 2){
						myElement.value = String(myElement.value) + String(event.target.id)
					}
				}else{
					//console.log("setting this value: ", event.target.id); 
					let newString = String(myElement.value) + String(event.target.id); 
					if(targetElement != "#DMR"){
						myElement.value = newString.replace(/^0+/ , "");
					}else{
						let newString = String(myElement.value) + String(event.target.id);
						myElement.value = newString
					}
				
				}
				
			}

			console.log("Target Element: ", targetElement, " Value: ", myElement.value)

			if(targetElement == "#check1_psid"){
				dispatch( updateCheck1(myElement.value) ).then((data, err) =>{
					console.log("updated check1")
				})

				dispatch(updateRvPressdrop(myElement.value) ).then((data, err) =>{

				})
			}

			if (targetElement == "#check2_psid"){
				dispatch( updateCheck2(myElement.value)) .then((data, err) =>{
					console.log("updated check2")
				})
			}

			if(targetElement == "#systemPSI"){
				dispatch(updateSystemPSI(myElement.value)).then((data, err) =>{

				})
			}
			if(targetElement == "#DMR"){
				dispatch(updateDMR(myElement.value)).then((data, err) =>{		
				})
			}
			if(targetElement == "#final_check1"){
				dispatch(updateFinalCheck1(myElement.value) ).then((data, err) =>{
				})
				dispatch( updateFinalRvPressdrop(myElement.value) ).then((data, err) =>{
				})
			}
			if(targetElement == "#final_check2"){
				dispatch(updateFinalCheck2(myElement.value) ).then((data, err) =>{		
				})
			}
			if(targetElement == "#rv_opened"){
				dispatch( updateRvOpenedAt(myElement.value) ).then((data, err) =>{
				})
			}

			if(targetElement == "#final_rvOpenedAt"){
				dispatch( updateFinalRvOpenedAt(myElement.value) ).then((data, err) =>{
				})
			}
			if(targetElement == "#air_opened"){
				dispatch( updateAirOpened(myElement.value) ).then((data, err) =>{
				})
			}
			if(targetElement == "#check_pressdrop"){
				dispatch( updateCheckPressdrop(myElement.value) ).then((data, err) =>{
				})
			}
			if(targetElement == "#final_air_opened"){
				dispatch( updateFinalAirOpened(myElement.value) ).then((data, err) =>{
				})
			}
			if(targetElement == "#final_check_pressdrop"){
				dispatch( updateFinalCheckPressdrop(myElement.value) ).then((data, err) =>{
				})
			}
			if(targetElement == "#pipesize"){
				dispatch( updatePipesize(myElement.value) ).then((data, err) =>{
				})
			}
			if(targetElement == "#physical_separation"){
				dispatch( updatePhysicalSeparation(myElement.value) ).then((data, err) =>{
				})
			}
			if(targetElement == "#final_pipesize"){
				dispatch( updateFinalPipesize(myElement.value) ).then((data, err) =>{
				})
			}
			if(targetElement == "#final_physical_separation"){
				dispatch( updateFinalPhysicalSeparation(myElement.value) ).then((data, err) =>{
				})
			}

		}


		dispatch( pendingUpdatedValue() )
	}	

	return(
		
		<Container fluid id="numberPad">
			<Row  >
				<ButtonGroup vertical>
					<ButtonGroup>
						<Button onClick= { grabValue } bsPrefix="button-pad" id="1" >1 </Button>
						<Button onClick= { grabValue } bsPrefix="button-pad" id="2"  >2</Button>
						<Button onClick= { grabValue } bsPrefix="button-pad" id="3" >3 </Button>
					</ButtonGroup>
					<ButtonGroup>
						<Button onClick= { grabValue } bsPrefix="button-pad" id="4" >4 </Button>
						<Button onClick= { grabValue } bsPrefix="button-pad" id="5" >5</Button>
						<Button onClick= { grabValue } bsPrefix="button-pad" id="6" >6 </Button>
					</ButtonGroup>
					<ButtonGroup>
						<Button onClick= { grabValue } bsPrefix="button-pad" id="7" >7 </Button>
						<Button onClick= { grabValue } bsPrefix="button-pad" id="8" >8</Button>
						<Button onClick= { grabValue } bsPrefix="button-pad" id="9" >9 </Button>
					</ButtonGroup>
					<ButtonGroup>
						<Button onClick= { grabValue } bsPrefix="button-pad" id="0" >0 </Button>
						<Button onClick= { grabValue } bsPrefix="button-pad" id=".">.</Button>
						<Button onClick= { grabValue } bsPrefix="button-pad" id="CL" >CL </Button>
					</ButtonGroup>
				</ButtonGroup>
			</Row>
		</Container>

	); 
}