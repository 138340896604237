import {
    SET_REMOVED, CLEAR_REMOVED
} from "../../actions/removed";

const _null = null;

export default (state = _null, { type, truefalse }) => {
    Object.freeze(state);
    switch (type) {
        case SET_REMOVED:
            return truefalse;
        case CLEAR_REMOVED:
            return _null
        default:
            return state;
    }
};